import { ApiWrapper, REQUEST } from ".";
import { API_URL } from "../config/config";
import { createQueryStringURL } from "../utils/common";

/**
 * Fetch all chats list
 * @returns
 */
export const fetchChatsList = async (school_id = "") => {
  return await ApiWrapper({
    url: `${API_URL}network_modules/list_messages?school_id=${school_id}`,
  });
};

/**
 * Fetch conversation
 * @param {*} conversationId
 * @returns
 */
export const fetchConversation = async (conversationId, options = {}) => {
  var link = `${API_URL}network_modules/view_messages`;
  const queryStringURL = createQueryStringURL(link, options);
  return await ApiWrapper({
    url:
      Object.keys(options).length > 0
        ? `${queryStringURL}&conversation_id=${conversationId}`
        : `${link}?conversation_id=${conversationId}`,
  });
};

export const getContactsList = async (options = {}, selectedSchoolId) => {
  var link = "network_modules";
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");
  link =
    Object.keys(options).length > 0
      ? `${link}?${queryString}&school_id=${selectedSchoolId}`
      : `${link}?school_id=${selectedSchoolId}`;
  return await ApiWrapper({
    url: `${API_URL}${link}`,
  });
};

/**
 * Get all filters for dropdown
 * @returns
 */
export const getDiscoverNetworkFilters = async (school_id) => {
  return await ApiWrapper({
    url: `${API_URL}network_modules/discover_search_list?school_id=${school_id}`,
  });
};

/**
 * Remove conversation/chat
 * @param {*} conversationId
 * @returns
 */
export const deleteChat = async (conversationId) => {
  return await ApiWrapper({
    url: `network_modules/leave_or_delete_chat?conversation_id=${conversationId}`,
  });
};

/**
 * Mute or Unmute chat
 * @param {*} conversationId
 * @returns
 */
export const muteOrUnmuteChat = async (conversationId) => {
  return await ApiWrapper({
    url: `network_modules/mute_chat?conversation_id=${conversationId}`,
  });
};

/**
 * Request a meeting
 * @param {*} receiverId
 * @returns
 */
export const requestMeeting = async (data) => {
  return await ApiWrapper({
    url: `network_modules/request_meeting`,
    method: REQUEST.POST,
    data,
  });
};

/**
 * Report a chat
 * @param {*} receiverId
 * @returns
 */
export const reportChat = async (data) => {
  return await ApiWrapper({
    url: `network_modules/report_chat`,
    method: REQUEST.POST,
    data,
  });
};
/**
 * Search Conversation
 * @param {*} search
 * @returns
 */
export const searchConversation = async (
  search,
  page = 1,
  selectedSchoolId
) => {
  return await ApiWrapper({
    url: `network_modules/search_messages?search=${search}&page=${page}&school_id=${selectedSchoolId}`,
  });
};

export const sendChatMessageAttachmentAPI = async (
  conversation_window_id = null,
  file = null,
  content = ""
) => {
  const payload = new FormData();
  payload.append("id", conversation_window_id);
  payload.append("file", file);
  payload.append("content", content);

  return await ApiWrapper({
    url: `/conversations/${conversation_window_id}/attachments`,
    method: REQUEST.POST,
    data: payload,
  });
};

/**
 * Get recommended ambassadors
 * @returns ApiWrapper
 */
export const getRecommendedAmbassadors = async (schoolId) => {
  return await ApiWrapper({
    url: `ambassadors/recommended${schoolId ? `?school_id=${schoolId}` : ""}`,
  });
};
