import React, { useState, useEffect, memo } from "react";

import { getUsersList, getStudentsFilters } from "../../../api/widget";
import { ROUTE_TYPES } from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetChatsTab.module.scss";
import WidgetSignUpBar from "../../atoms/WidgetSignUpBar/WidgetSignUpBar";
import NSwitch from "../../atoms/NSwitch/NSwitch";
import WidgetSignUpOverlay from "../WidgetSignUpOverlay/WidgetSignUpOverlay";
import WidgetUserDetails from "../WidgetUserDetails/WidgetUserDetails";
import DiscoverNetworkFilters from "../DiscoverNetworkFilters/DiscoverNetworkFilters";
import ChatMapView from "../ChatMapView/ChatMapView";
import ChatListView from "../ChatListView/ChatListView";

const cx = createModuleStyleExtractor(styles);
const tabViewOptions = ["left", "right"];

const WidgetChatsTab = ({
  schoolData = {},
  selectedTab = null,
  resetChatViewStates = false,
  setResetChatViewStates = () => {},
}) => {
  // Filter States
  const [selectedNetworkFilters, setSelectedNetworkFilters] = useState({});
  const [networkFilterOptions, setNetworkFilterOptions] = useState([]);
  // User States
  const [users, setUsers] = useState(null);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [activeUserId, setActiveUserId] = useState(null);
  const [tabUserType, setTabUserType] = useState(null);
  const [tabUserTypeOptions, setTabUserTypeOptions] = useState([]);
  const [isEmptyContacts, setIsEmptyContacts] = useState(false);
  // UI states
  const [tabView, setTabView] = useState(tabViewOptions[0]);
  const [showSignupOverlay, setShowSignupOverlay] = useState(false);
  const [viewingUserDetails, setViewingUserDetails] = useState(false);

  // Calls user api
  const getUser = async () => {
    setFetchingUsers(true);
    const response = await getUsersList(
      selectedNetworkFilters?.country,
      selectedNetworkFilters?.degree,
      selectedNetworkFilters?.major,
      tabUserType?.key,
      schoolData?.token
    );
    if (response?.success && response?.data) {
      const userData =
        response?.data?.users?.map((contact) =>
          contact.user_type === "Staff" && !contact.country_of_origin
            ? // Need to set US country for staff accounts without country
              {
                ...contact,
                country_of_origin: "United States",
                country_image: "https://flagpedia.net/data/flags/normal/us.png",
              }
            : contact
        ) || [];
      setUsers(userData);
      if (response?.data?.users?.length === 0) {
        setIsEmptyContacts(true);
      } else {
        setIsEmptyContacts(false);
      }
    }
    setFetchingUsers(false);
  };

  useEffect(() => {
    // Get Student Filters
    (async () => {
      setFetchingUsers(true);
      const response = await getStudentsFilters(schoolData?.token);
      if (response?.success && response?.data) {
        setNetworkFilterOptions(response.data);

        // Build NSwtich tab options based on filter data
        const { user_types = [] } = response.data || {};
        let _tabUserTypeOptions = [];
        user_types?.includes("Student") &&
          _tabUserTypeOptions.push({ key: "Student", value: "Students" });
        user_types?.includes("Alumni") &&
          _tabUserTypeOptions.push({ key: "Alumni", value: "Alumni" });
        user_types?.includes("Staff") &&
          _tabUserTypeOptions.push({ key: "Staff", value: "Staff" });
        setTabUserTypeOptions(_tabUserTypeOptions);
        // Select first option
        setTabUserType(_tabUserTypeOptions[0]);
      } else {
        setNetworkFilterOptions({});
      }
      setFetchingUsers(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    tabUserType && getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNetworkFilters, schoolData?.token, tabUserType]);

  useEffect(() => {
    if (selectedTab === "Chat" && resetChatViewStates) {
      setSelectedNetworkFilters({});
      setActiveUserId(null);
      setTabUserType(tabUserTypeOptions[0]);
      setTabView(tabViewOptions[0]);
      setShowSignupOverlay(false);
      setViewingUserDetails(false);
      setResetChatViewStates(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, resetChatViewStates]);

  const onChangeFilter = (key, item) => {
    setSelectedNetworkFilters({
      ...selectedNetworkFilters,
      [key]: item,
    });
  };

  const onClearFilter = () => {
    if (Object.keys(selectedNetworkFilters).length > 0) {
      setSelectedNetworkFilters({});
    }
  };

  const showOverlay = () => setShowSignupOverlay(true);

  const closeOverlay = () => setShowSignupOverlay(false);

  const handleViewDetailsClick = (user = {}) => {
    setViewingUserDetails(true);
    setActiveUserId(user?.id ?? null);
  };
  const handleBackUserDetails = () => {
    setViewingUserDetails(false);
    setActiveUserId(null);
  };

  return (
    <>
      {showSignupOverlay && (
        <WidgetSignUpOverlay
          token={schoolData?.token}
          title={"Sign up to start chatting"}
          route_type={ROUTE_TYPES.CHAT}
          closeOverlay={closeOverlay}
        />
      )}
      <WidgetSignUpBar
        text="Get full access on Interstride’s portal"
        buttonText="Sign up for free"
        onSignupClick={showOverlay}
      />
      {viewingUserDetails ? (
        <WidgetUserDetails
          handleBackUserDetails={handleBackUserDetails}
          activeUserId={activeUserId}
          token={schoolData?.token}
          tabView={tabView}
          showBackButton={true}
          showChatButton={true}
        />
      ) : (
        <div className={cx("m-widget-chats-tab")}>
          <h2 className={cx("m-widget-chats-tab__title")}>
            Connect with students studying at {schoolData?.name}
          </h2>
          {tabUserTypeOptions?.length > 1 && (
            <div className={cx("m-widget-chats-tab__user-type-switch")}>
              <NSwitch
                options={tabUserTypeOptions}
                activeSide={tabUserType}
                setActiveSide={setTabUserType}
                variant="buttons"
              />
            </div>
          )}
          {/* checking for length above 2 because there is a default option called "All" and another option if there's only one*/}
          {networkFilterOptions?.user_types?.length > 0 && (
            <DiscoverNetworkFilters
              networkFilterOptions={networkFilterOptions}
              selectedNetworkFilters={selectedNetworkFilters}
              onChangeFilter={onChangeFilter}
              onClearFilter={onClearFilter}
              tab={tabView}
              setTab={setTabView}
              variant="widget"
              showDropdownFilters={
                networkFilterOptions?.user_types?.length > 2 &&
                tabUserType?.value !== "Staff"
              }
            />
          )}

          {tabView === tabViewOptions[0] ? (
            <ChatMapView
              contacts={users ?? []}
              contactLoader={fetchingUsers}
              isEmptyContacts={isEmptyContacts}
              handleMeeting={showOverlay}
              variant="widget"
              handleViewDetailsClick={handleViewDetailsClick}
              handleMatchMe={showOverlay}
              matchMeModal={showOverlay}
            />
          ) : (
            <ChatListView
              contacts={users ?? []}
              contactLoader={fetchingUsers}
              variant="widget"
              isEmptyContacts={isEmptyContacts}
              matchMeModal={showOverlay}
              handleMeeting={showOverlay}
              handleMatchMe={showOverlay}
              handleViewDetailsClick={handleViewDetailsClick}
            />
          )}
        </div>
      )}
    </>
  );
};
export default memo(WidgetChatsTab);
