import React, { memo, useId, useMemo } from "react";
import { ShimmerPostItem } from "react-shimmer-effects";

import DiscoverNetworkCard from "../../atoms/DiscoverNetworkCard/DiscoverNetworkCard";
import MatchMeCard from "../../atoms/MatchMeCard/MatchMeCard";

import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import { getRandomInteger } from "../../../utils/common";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./ChatListView.module.scss";

import noContactImg from "../../../assets/images/discover_no_contacts.svg";

const cx = createModuleStyleExtractor(styles);
const MATCH_ME_LIMIT = 8;
const ChatListView = ({
  contacts = [],
  isEmptyContacts,
  contactLoader,
  selectedSchoolId,
  matchMeModal = {},
  handleMeeting = () => {},
  handleMatchMe = () => {},
  variant = "chat", // possible values => [chat, widget]
  handleViewDetailsClick = () => {},
}) => {
  const id = useId();

  // This useMemo will inject MatchMeComponent's flag into contacts array at random location
  const adjustedContacts = useMemo(() => {
    let _contacts = [...contacts];
    const isMatchMeInserted = _contacts.some(
      (item) => item.isMatchMeComponent === true
    );
    if (contacts?.length > 0 && !isMatchMeInserted) {
      const indexMatchMeCard = getRandomInteger(1, MATCH_ME_LIMIT);
      _contacts?.splice(indexMatchMeCard, 0, { isMatchMeComponent: true });
    }
    return _contacts;
  }, [contacts]);

  return (
    <div className={cx("m-chat-list-view")}>
      {contactLoader ? (
        <div className={cx("m-chat-list-view__users")}>
          <Row>
            {[...Array(12)].map((_, index) => (
              <Col
                xs={12}
                sm={6}
                md={3}
                lg={3}
                key={`${id}_${index}`}
                styles={{ marginBottom: "10px" }}
              >
                <ShimmerPostItem
                  card
                  title
                  cta
                  imageType="circular"
                  imageWidth={70}
                  imageHeight={70}
                  contentCenter
                />
              </Col>
            ))}
          </Row>
        </div>
      ) : !contactLoader && isEmptyContacts ? (
        <div className={cx("m-chat-list-view__users_no_network")}>
          <img src={noContactImg} alt="noContacts" />
          <p className={cx("m-chat-list-view__users_no_network_primary-text")}>
            Refine your search to find suitable ambassadors.
          </p>
          <p
            className={cx("m-chat-list-view__users_no_network_secondary-text")}
          >
            No matching results found.
          </p>
        </div>
      ) : (
        <div
          className={cx([
            "m-chat-list-view__users__cards",
            variant === "widget"
              ? "m-chat-list-view__users__cards--widget"
              : "",
          ])}
        >
          {adjustedContacts?.map((user, index) => (
            <div key={user.id || index}>
              {user?.isMatchMeComponent ? (
                <MatchMeCard
                  matchMeModal={matchMeModal}
                  handleMatchMe={handleMatchMe}
                  variant={variant}
                />
              ) : (
                <DiscoverNetworkCard
                  user={user}
                  handleMeeting={handleMeeting}
                  selectedSchoolId={selectedSchoolId}
                  handleViewDetailsClick={handleViewDetailsClick}
                  variant={variant}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default memo(ChatListView);
