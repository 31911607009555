import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  conversationChannel: null,
  notificationChannel: null,
  conversationReactionsChannel: null,
};

export const webSocketReducer = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    setConversationChannel: (state, action) => {
      state.conversationChannel = action.payload;
    },
    setNotificationChannel: (state, action) => {
      state.notificationChannel = action.payload;
    },
    setConversationReactionsChannel: (state, action) => {
      state.conversationReactionsChannel = action.payload;
    },
    resetInitialState: () => initialState,
  },
});

export const {
  setConversationChannel,
  setNotificationChannel,
  resetInitialState,
  setConversationReactionsChannel,
} = webSocketReducer.actions;

export default webSocketReducer.reducer;
