import React, { useEffect, useState, memo, useMemo } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import MatchMeCard from "../../atoms/MatchMeCard/MatchMeCard";
import MarkerWorldMap from "../../atoms/MarkerWorldMap/MarkerWorldMap";
import DiscoverNetworkCard from "../../atoms/DiscoverNetworkCard/DiscoverNetworkCard";

import styles from "./ChatMapView.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { getRandomInteger, groupBy } from "../../../utils/common";
import { isEmpty } from "../../../utils/validations";

import noContactImg from "../../../assets/images/discover_no_contacts.svg";

const cx = createModuleStyleExtractor(styles);
const MATCH_ME_LIMIT = 3;

const ChatMapView = ({
  contacts = [],
  isEmptyContacts,
  contactLoader,
  selectedSchoolId,
  handleMeeting = () => {},
  variant = "chat", // possible values => [chat, widget]
  handleMatchMe = () => {},
  matchMeModal = {},
  handleViewDetailsClick = () => {},
}) => {
  const [mapLoader, setShowMapLoader] = useState(true);
  const [groupedContacts, setGroupedContacts] = useState({});
  const [isEmptyGroupedContacts, setIsEmptyGroupedContacts] = useState(true);
  const [selectedNationality, setSelectedNationality] = useState(false);

  // This useMemo will inject MatchMeComponent's flag into contacts array at random location
  const adjustedContacts = useMemo(() => {
    let _contacts = selectedNationality
      ? groupedContacts?.[selectedNationality] ?? contacts
      : contacts;
    const isMatchMeInserted = _contacts.some(
      (item) => item.isMatchMeComponent === true
    );
    if (contacts?.length > 0 && !isMatchMeInserted) {
      const indexMatchMeCard = getRandomInteger(1, MATCH_ME_LIMIT);
      _contacts?.splice(indexMatchMeCard, 0, { isMatchMeComponent: true });
    }
    return _contacts;
  }, [contacts, groupedContacts, selectedNationality]);

  useEffect(() => {
    if (contactLoader) return;

    setShowMapLoader(true);
    setSelectedNationality(false);

    if (!contacts || isEmpty(contacts) || isEmptyContacts) {
      setGroupedContacts({});
      setShowMapLoader(false);
    } else {
      const countryWiseAmbassadors = groupBy(
        contacts,
        // Group by country
        "country_of_origin"
      );

      if (!countryWiseAmbassadors || isEmpty(countryWiseAmbassadors)) {
        setGroupedContacts({});
        setShowMapLoader(false);
      } else {
        setGroupedContacts({ ...countryWiseAmbassadors });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  useEffect(() => {
    if (!contactLoader) {
      if (groupedContacts && !isEmpty(groupedContacts)) {
        setIsEmptyGroupedContacts(false);
        setShowMapLoader(false);
      } else if (!mapLoader) setIsEmptyGroupedContacts(true);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupedContacts]);

  return (
    <div className={cx("m-chat-map-view")}>
      {contactLoader || mapLoader ? (
        <div className={cx("m-chat-map-view")}>
          <ShimmerThumbnail height={600} rounded />;
        </div>
      ) : !mapLoader && isEmptyGroupedContacts ? (
        <div className={cx("m-chat-map-view__empty")}>
          <img src={noContactImg} alt="noContacts" />
          <p className={cx("m-chat-map-view__empty_primary-text")}>
            Refine your search to find suitable ambassadors.
          </p>
          <p className={cx("m-chat-map-view__empty_secondary-text")}>
            No matching results found.
          </p>
        </div>
      ) : (
        <div className={cx("m-chat-map-view__main")}>
          <div className={cx("m-chat-map-view__main__map")}>
            <MarkerWorldMap
              data={groupedContacts}
              categoryLabel="nationality"
              valueLabel="user" // Staff + Ambassador
              valueType="list"
              onClick={(nationality) => {
                setSelectedNationality(nationality);
              }}
            />
          </div>
          <div className={cx("m-chat-map-view__main__list")}>
            {adjustedContacts?.map((user, index) => (
              <div key={user.id || index}>
                {user?.isMatchMeComponent ? (
                  <MatchMeCard
                    matchMeModal={matchMeModal}
                    handleMatchMe={handleMatchMe}
                    variant={variant}
                  />
                ) : (
                  <DiscoverNetworkCard
                    index={index}
                    user={user}
                    handleMeeting={handleMeeting}
                    selectedSchoolId={selectedSchoolId}
                    handleViewDetailsClick={handleViewDetailsClick}
                    variant={variant}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default memo(ChatMapView);
