import React from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./MatchMeCard.module.scss";
import { Button } from "../Button";

const cx = createModuleStyleExtractor(styles);

const MatchMeCard = ({
  handleMatchMe = () => {},
  variant = "chat", // possible values => [chat, widget, dashboard]
  matchMeModal = {},
}) => {
  return (
    <div
      className={cx([
        "m-match-me-card__match-me",
        variant === "widget"
          ? "m-match-me-card__match-me--widget"
          : variant === "dashboard"
          ? "m-match-me-card__match-me--dashboard"
          : "",
      ])}
    >
      <p
        className={cx([
          "m-match-me-card__match-me__title",
          variant === "dashboard"
            ? "m-match-me-card__match-me--dashboard__title"
            : "",
        ])}
      >
        Match me with an Ambassador
      </p>
      <p className={cx("m-match-me-card__match-me__body")}>
        We’ll connect you directly with an ambassador who is the best fit for
        you.
      </p>
      <div className={cx("m-match-me-card__match-me__button")}>
        <Button isSubmitting={matchMeModal.loadingAPI} onClick={handleMatchMe}>
          Match me
        </Button>
      </div>
    </div>
  );
};

export default MatchMeCard;
