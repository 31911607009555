import React, { useState, memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import styles from "./ResetPassword.module.scss";
import { resetPassword } from "../../../api/onboarding";
import { ROUTES } from "../../../routes";
import toastify from "../../../utils/toast";
import BackIcon from "../../../assets/Icons/BackIcon";
import {
  validateString,
  validatePassword,
  isEmpty,
} from "../../../utils/validations";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import PasswordTextbox from "../../atoms/PasswordTextbox/PasswordTextbox";

const cx = createModuleStyleExtractor(styles);

const ResetPassword = ({ email, resetPasswordToken }) => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    Mixpanel.track(`Reset password visited`);
  }, []);

  const handleResetPasswordClick = async (password, confirmPassword) => {
    setIsSubmitting(true);
    const response = await resetPassword({
      user: {
        email,
        password,
        password_confirmation: confirmPassword,
        reset_password_token: resetPasswordToken,
      },
    });

    if (response.success && response.data) {
      if (response?.message) toastify(response.message);
      setIsSubmitting(false);
      navigate(ROUTES.LOGIN);
    } else {
      setIsSubmitting(false);
      toastify(response?.message);
    }
  };

  return (
    <div className={cx("m-reset-password-container")}>
      <div className={cx("m-reset-password-container__back-btn")}>
        <a
          href="/forgot-password"
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            navigate(`${ROUTES.FORGOT_PASSWORD}?email=${email}`);
          }}
        >
          <BackIcon />
          Back
        </a>
      </div>
      <div className={cx("m-reset-password-container__content")}>
        <div className={cx("m-reset-password-container__content__unlogo")}>
          <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
        </div>
        <h2>Your password is reset</h2>
        <h5>Choose your new password below.</h5>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          initialTouched={{
            password: true,
          }}
          validateOnChange={true}
          validateOnBlur={true}
          validate={(values) => {
            let errors = {};

            if (validateString(values.password)) {
              errors.password = "Password is required";
            } else if (values.password?.length < 8) {
              errors.password = "Password must have at least 8 characters";
            } else if (validatePassword(values.password)) {
              errors.password =
                "Password should have both upper and lowercase characters";
            }

            if (
              !values.confirmPassword ||
              values.confirmPassword.trim() === ""
            ) {
              errors.confirmPassword = "Confirm password is required";
            }
            if (
              values.confirmPassword &&
              values.password !== values.confirmPassword
            ) {
              errors.confirmPassword = "Passwords don't match";
            }

            return errors;
          }}
          onSubmit={(values) => {
            handleResetPasswordClick(values.password, values.confirmPassword);
          }}
        >
          {({ isValid, values }) => (
            <Form>
              <div
                className={cx("m-reset-password-container__content__formfield")}
              >
                <Field name="password">
                  {({ field, meta }) => (
                    <div>
                      <PasswordTextbox
                        {...field}
                        value={values.password}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        name={"password"}
                        label="Enter new password"
                        placeholder={"Password"}
                        error={meta.touched && meta.error}
                      />
                    </div>
                  )}
                </Field>

                <Field name="confirmPassword">
                  {({ field, meta }) => (
                    <div>
                      <PasswordTextbox
                        {...field}
                        value={values.confirmPassword}
                        onChange={(e) => {
                          field.onChange(e);
                        }}
                        name={"confirmPassword"}
                        label="Confirm new password"
                        placeholder={"Password"}
                        error={meta.touched && meta.error}
                      />
                    </div>
                  )}
                </Field>

                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={!isValid || isEmpty(values.password)}
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default memo(ResetPassword);
