import React, { useState, memo, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import Bugsnag from "@bugsnag/js";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import GreyLink from "../../atoms/GreyLink/GreyLink";
import { setLoginData } from "../../../redux/reducers/userReducer";
import styles from "./AuthLogin.module.scss";
import {
  emailCheck,
  resendVerifyEmail,
  prospectLogin,
  ssoLogin,
  socialLoginAuthorization,
  socialLoginAuthorizationLinkedin,
  socialLoginAuthorizationGoogle,
} from "../../../api/onboarding";
import { ROUTES } from "../../../routes";
import {
  validateString,
  validateEmail,
  isEmpty,
} from "../../../utils/validations";
import AuthFacebook from "../../atoms/AuthFacebook/AuthFacebook";
import AuthGoogle from "../../atoms/AuthGoogle/AuthGoogle";
import AuthLinkedin from "../../atoms/AuthLinkedin/AuthLinkedin";
import toastify from "../../../utils/toast";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";
import Link from "../../atoms/Link/Link";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import {
  getCommonSignInFields,
  setMSClarityTagsAfterLogin,
  setMSClarityTagsOnLogin,
} from "../../../utils/common";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import PasswordTextbox from "../../atoms/PasswordTextbox/PasswordTextbox";

const cx = createModuleStyleExtractor(styles);

const AuthLogin = ({
  goToSignUp,
  goToProspectForm,
  schoolData,
  ssoToken,
  verifyEmail,
  defaultEmail,
  token,
  role,
}) => {
  let formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validToken = schoolData && schoolData !== null;

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(verifyEmail);
  const [password, setPassword] = useState("");
  const [tnc, setTnc] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(true);
  const [showResendEmailLink, setShowResendEmailLink] = useState(false);
  const [showCareerLink, setShowCareerLink] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (ssoToken && ssoToken !== "unauthorized") {
      handleSSOLogin();
    } else if (ssoToken && ssoToken === "unauthorized") {
      toastify(
        "You are not authorized to access Interstride Admission portal."
      );
      navigate(ROUTES.LOGIN_WITH_PARAMS);
    }
    if (defaultEmail) setEmail(defaultEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emailCheckClick = async () => {
    setIsSubmitting(true);
    const response = await emailCheck(email);

    if (response.success && response.data) {
      // Don't allow user to login if authorized false for special scenarios of pending/revoked user
      if (response.data.authorized === false) {
        if (response.message) toastify(response.message);
        else toastify("Your email address is not registered with us.");
      } else if (response.success && response.data.is_blocked) {
        toastify(
          "Your account is blocked. Please contact your admissions office for more information."
        );
      }
      // If a user is registered and only has career access then show career link
      else if (response.data.career === true) {
        setShowCareerLink(true);
      }
      // If a user is SSO then redirect to university SSO login page
      else if (response.data.sso === true) {
        window.location.href =
          response.data.base_url +
          "?entityID=" +
          response.data.entity_id +
          "&target=" +
          response.data.target_url;
      }
      // If a user is registered
      else if (response.data.user === true) {
        // If user has completed email verification then allow to login
        if (response.data.email_verified === true) {
          setShowPassword(true);
        }
        // If user has not completed email verification then show resend email link
        else {
          toastify("Please verify your email");
          setShowResendEmailLink(true);
        }
      }
      //  If a user is an invited ambassador but hasn't registered yet then show ambassador signup page
      else if (response.data.verified_ambassador === true) {
        navigate(`${ROUTES.AMBASSADOR_SIGN_UP}?redirect=login`, {
          replace: true,
        });
      }
      // No case is passed hence user is unauthorized.
      else {
        if (token && role === "prospect") {
          goToProspectForm(email);
        }
        toastify(
          "Your email address is not registered with us. Please complete the process with Sign Up"
        );
      }
      setIsSubmitting(false);
    } else {
      setIsSubmitting(false);
      toastify("Oops! something went wrong. Please try again");
    }
  };

  const resetVerifyEmailClick = async () => {
    setShowResendEmailLink(false);
    const response = await resendVerifyEmail({ email });
    if (response.success) toastify("Email sent successfully");
  };

  const handleLoginResponse = (response, source = "Normal") => {
    if (response.success) {
      const { users = {}, schools = [] } = response.data;

      sessionStorage.setItem("isLogin", "1");
      // Set user details for bugsnag
      Bugsnag.setUser(users.id, users.email, users.name);
      Mixpanel.identify(users.id);
      setMSClarityTagsAfterLogin(users, source);

      dispatch(
        setLoginData({
          user: { ...users },
          schools,
          keepSignedIn,
        })
      );
      Mixpanel.track("Login successfully", {
        Source: source,
      });
      // Redirection to route after login is handled from App.js effects
    } else {
      setIsSubmitting(false);
      toastify(response.message);
    }
  };

  const handleLoginClick = async () => {
    setIsSubmitting(true);
    // console.log(email, password, keepSignedIn);

    const reqData = new FormData();
    reqData.append("user[email]", email.trim());
    reqData.append("user[password]", password);

    if (keepSignedIn) reqData.append("remember_me", true);

    if (validToken) {
      reqData.append("user[school_id]", schoolData.id);
      reqData.append("user[school_token]", token);
    }

    // Append common sign in fields of user_device and UTM parameters
    const commonSignInFields = getCommonSignInFields();
    Object.keys(commonSignInFields).forEach((category) => {
      Object.keys(commonSignInFields?.[category]).forEach((field) => {
        reqData.append(
          `${category}[${field}]`,
          commonSignInFields[category][field]
        );
      });
    });

    const response = await prospectLogin(reqData);
    handleLoginResponse(response, "Normal");
  };

  const handleSSOLogin = async () => {
    const response = await ssoLogin({
      token: ssoToken,
      ...getCommonSignInFields(),
    });

    if (response.success) {
      handleLoginResponse(response, "SSO");
    } else {
      toastify(response.message);
      navigate(ROUTES.LOGIN_WITH_PARAMS);
    }
  };

  const handleSocialLoginResponse = (response, Source) => {
    if (response.success && response.data) {
      // Don't allow user to login if authorized false for special scenarios of pending/revoked user
      if (response.data.authorized === false) {
        if (response.message) toastify(response.message);
        else toastify("Your email address is not registered with us.");
      }
      // If a user is registered and only has career access then show career link
      else if (response.data.career === true) {
        setShowCareerLink(true);
      }
      // If a user is SSO then redirect to university SSO login page
      else if (response.data.sso === true) {
        window.location.href =
          response.data.base_url +
          "?entityID=" +
          response.data.entity_id +
          "&target=" +
          response.data.target_url;
      }
      // If a user is registered
      else if (response.data.users && !isEmpty(response.data.users)) {
        handleLoginResponse(response, Source);
      }
      //  If a user is an invited ambassador but hasn't registered yet then show ambassador signup page
      else if (response.data.verified_ambassador === true) {
        navigate(`${ROUTES.AMBASSADOR_SIGN_UP}?redirect=login`, {
          replace: true,
        });
      }
      // No case is passed hence user is unauthorized.
      else {
        toastify(
          "Your email address is not registered with us. Please complete the process with Sign Up"
        );
      }
    } else {
      if (response.message) toastify(response.message);
      else toastify("Your email address is not registered with us.");
    }
  };

  const checkFacebookAuthentication = async (callbackData) => {
    // console.log("Facebook callback", callbackData);
    if (
      callbackData &&
      callbackData.status !== "unknown" &&
      callbackData.accessToken
    ) {
      const params = {
        provider: "facebook",
        uid: callbackData.userID,
        id_token: callbackData.accessToken,
        info: {
          email: callbackData.email,
          first_name: callbackData.first_name,
          last_name: callbackData.last_name,
          profile_url: callbackData.picture
            ? callbackData.picture.data.url
            : "",
        },
        ...getCommonSignInFields(),
      };

      if (validToken) {
        params.school_id = schoolData.id;
        params.school_token = token;
      }

      const response = await socialLoginAuthorization(params);
      handleSocialLoginResponse(response, "Facebook");
    }
  };

  const checkGoogleAuthentication = async (callbackData) => {
    console.log("Google callback", callbackData);
    if (callbackData && !callbackData.error) {
      const url = new URL(window.location.href);
      const params = {
        provider: "google",
        code: callbackData.code,
        redirect_uri: url.origin,
        ...getCommonSignInFields(),
      };

      if (validToken) {
        params.school_id = schoolData.id;
        params.school_token = token;
      }

      const response = await socialLoginAuthorizationGoogle(params);
      handleSocialLoginResponse(response, "Google");
    }
  };

  const checkLinkedinAuthentication = async (code, redirect_uri) => {
    if (code) {
      const params = {
        provider: "linkedin",
        code,
        redirect_uri,
        ...getCommonSignInFields(),
      };
      if (validToken) {
        params.school_id = schoolData.id;
        params.school_token = token;
      }
      // Validate Code from BE API
      const response = await socialLoginAuthorizationLinkedin(params);
      handleSocialLoginResponse(response, "LinkedIn");
    } else {
      toastify("Oops! something went wrong. Please try again");
    }
  };

  useEffect(() => {
    if (showPassword && formRef.current) formRef.current.validateForm();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return (
    <div className={cx("m-authlogin-container")}>
      <div className={cx("m-authlogin-container__content")}>
        {validToken ? (
          <>
            <div className={cx("m-authlogin-container__content__unlogo")}>
              <img src={schoolData.logo_url} alt="University logo" />
            </div>
            <div className={cx("m-authlogin-container__content__sitelogo")}>
              <h3>Powered by</h3>
              <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
            </div>
            <h2>Welcome to the Admissions Portal</h2>

            <h5>
              New to Interstride?
              <a
                href="/sign-up"
                onClick={(e) => {
                  e?.preventDefault && e.preventDefault();
                  goToSignUp(email);
                }}
              >
                Sign Up
              </a>
            </h5>
          </>
        ) : (
          <>
            <div className={cx("m-authlogin-container__content__unlogo")}>
              <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
            </div>
            <h2>Welcome back!</h2>
          </>
        )}

        <h5>Log in with an account</h5>
        <ul>
          <li>
            <AuthGoogle callback={checkGoogleAuthentication} />
          </li>
          <li>
            <AuthFacebook callback={checkFacebookAuthentication} />
          </li>
          <li>
            <AuthLinkedin callback={checkLinkedinAuthentication} />
          </li>
        </ul>
        <h5 className={cx("m-authlogin-container__content--or")}>
          <span></span>
          <span className={cx("m-authlogin-container__content--or--text")}>
            Or log in with email
          </span>
          <span></span>
        </h5>
        <Formik
          innerRef={formRef}
          initialValues={{
            email: defaultEmail ? defaultEmail : verifyEmail ? verifyEmail : "",
            password: "",
          }}
          validateOnMount={true}
          validateOnChange={true}
          validateOnBlur={true}
          validate={(values) => {
            let errors = {};

            if (validateString(values.email)) {
              errors.email = "Email is required";
            } else if (validateEmail(values.email)) {
              errors.email = "Email is not valid";
            }
            if (showPassword && validateString(password)) {
              errors.password = "Password is required";
            }

            return errors;
          }}
          onSubmit={() => {
            setMSClarityTagsOnLogin(email);
            if (showPassword) handleLoginClick();
            else emailCheckClick();
          }}
        >
          {({ isValid }) => (
            <Form>
              <div className={cx("m-authlogin-container__content__formfield")}>
                <Field name="email">
                  {({ field, meta }) => (
                    <div>
                      <MuiTextbox
                        {...field}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          field.onChange(e);
                          setShowCareerLink(false);
                          setShowResendEmailLink(false);
                        }}
                        name={"email"}
                        label="Enter your email"
                        placeholder={"Enter your email"}
                        error={meta.touched && meta.error}
                      />
                    </div>
                  )}
                </Field>
                {showResendEmailLink === true && showPassword === false && (
                  <div
                    className={cx(
                      "m-authlogin-container__content__formfield__resendEmail"
                    )}
                  >
                    If you did not receive the email click{" "}
                    <GreyLink onClick={resetVerifyEmailClick}>here </GreyLink>
                    to resend
                  </div>
                )}
                {showCareerLink === true && showPassword === false && (
                  <div
                    className={cx(
                      "m-authlogin-container__content__formfield__career-text"
                    )}
                  >
                    You are already registered with our career portal. Please
                    click{" "}
                    <Link
                      href="https://student.interstride.com/"
                      target="_blank"
                    >
                      here
                    </Link>{" "}
                    to login to our career portal.
                  </div>
                )}
                {showPassword && (
                  <Field name="password">
                    {({ field, meta }) => (
                      <div>
                        <PasswordTextbox
                          {...field}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          name={"password"}
                          label="Enter your password"
                          placeholder={"Enter your password"}
                          type="password"
                          error={meta.touched && meta.error}
                        />
                      </div>
                    )}
                  </Field>
                )}
                <div
                  className={cx(
                    "m-authlogin-container__content__formfield__keepMeSignin"
                  )}
                >
                  {showPassword ? (
                    <Checkbox
                      value={keepSignedIn}
                      onChange={(e) => setKeepSignedIn(e.target.checked)}
                      name={"signed_in"}
                      label="Keep me signed in on this device"
                    />
                  ) : (
                    <div
                      className={cx(
                        "m-authlogin-container__content__formfield__keepMeSignin--T-C"
                      )}
                    >
                      <Checkbox
                        value={tnc}
                        onChange={(e) => {
                          setTnc(e.target.checked);
                        }}
                        name={"tnc"}
                      />
                      <span>
                        I agree to the Interstride{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            "https://www.interstride.com/terms-and-conditions/"
                          }
                          className="link-focus"
                        >
                          Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={"https://www.interstride.com/privacy-policy/"}
                          className="link-focus"
                        >
                          Privacy Policy
                        </a>
                      </span>
                    </div>
                  )}
                </div>

                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={
                    !tnc || !isValid || (showPassword && isEmpty(password))
                  }
                >
                  {showPassword ? "Log in" : "Next"}
                </Button>

                {showPassword === true && (
                  <GreyLink
                    onClick={() => {
                      Mixpanel.track("Forgot password visited");
                      navigate(
                        `${ROUTES.FORGOT_PASSWORD}?email=${encodeURIComponent(
                          email
                        )}`
                      );
                    }}
                  >
                    Forgot Password?
                  </GreyLink>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default memo(AuthLogin);
