import { ApiWrapper, REQUEST } from ".";

/**
 * Validate university token from widget on the university site
 * @param {*} token
 * @returns
 */
export const validateWidgetToken = async (token) => {
  return await ApiWrapper({
    url: `validate/school`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get students list
 * @param {*} options filters
 * @param {*} token
 * @returns
 */
export const getStudentsList = async (options = {}, token) => {
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");

  return await ApiWrapper({
    url: `students?${queryString}${
      !options["user_type"] ? "&user_type=Student-Alumni" : ""
    }`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get students filters list
 * @param {*} token
 * @returns
 */
export const getStudentsFilters = async (token) => {
  return await ApiWrapper({
    url: `filters`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get staff list
 * @param {*} token
 * @returns
 */
export const getStaffList = async (token) => {
  return await ApiWrapper({
    url: `administrators`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get community/topics list
 * @param {*} token
 * @returns
 */
export const getCommunityList = async (token) => {
  return await ApiWrapper({
    url: `topics/widget`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get webinars list
 * @param {*} token
 * @returns
 */
export const getWebinarsList = async (token) => {
  return await ApiWrapper({
    url: `webinars/widget`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get student service categories list
 * @param {*} token
 * @returns
 */
export const getServiceCategories = async (token) => {
  return await ApiWrapper({
    url: `deals/widget/categories`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get student services list by category
 * @param {*} category_id
 * @param {*} token
 * @returns
 */
export const getStudentServicesList = async (category_id, token) => {
  return await ApiWrapper({
    url: `deals/widget?deal_category_id=${category_id}`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get resource categories list
 * @param {*} token
 * @returns
 */
export const getResourceCategories = async (token) => {
  return await ApiWrapper({
    url: `videos/widget/categories`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get resources list by category
 * @param {*} category_id
 * @param {*} token
 * @returns
 */
export const getResourcesList = async (category_id, token) => {
  return await ApiWrapper({
    url: `videos/widget?video_category_id=${category_id}`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get all ambassadors list
 * @param {*} options filters
 * @param {*} token
 * @returns
 */
export const getChatPopupContacts = async (options = {}, token) => {
  const queryString = Object.keys(options)
    .map((option) => `${option}=${options[option]}`)
    .join("&");

  return await ApiWrapper({
    url: `students?${queryString}${
      !options["user_type"] ? "&user_type=Student-Alumni" : ""
    }`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get employment graph data
 * @param {*} token
 * @returns
 */
export const getEmploymentData = async (token) => {
  return await ApiWrapper({
    url: `opts/widget/graph_data`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get user details
 * @param {*} token
 * @returns
 */
export const getUserDetails = async (token = null, userId = null) => {
  return await ApiWrapper({
    url: `users/${userId}/widget`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

/**
 * Get user list
 * @param {String} country
 * @param {String} degree
 * @param {String} major
 * @param {String} user_type
 * @param {String} token
 * @returns Axios APIWrapper
 */
export const getUsersList = async (
  country = null,
  degree = null,
  major = null,
  user_type = null,
  token = null
) => {
  return await ApiWrapper({
    url: "students",
    method: REQUEST.GET,
    config: {
      headers: { token },
      params: { country, degree, major, user_type },
    },
  });
};

/**
 * Get places list
 * @param {*} token
 * @param {Number} place_category_id
 * @param {Number} school_id
 * @returns
 */
export const getWidgetPlacesList = async (
  school_id,
  token,
  place_category_id
) => {
  return await ApiWrapper({
    url: `places/widget?place_category_id=${place_category_id}&school_id=${school_id}`,
    method: REQUEST.GET,
    config: { headers: { token } },
  });
};

export const getWidgetCalculatorFields = async (
  school_id = null,
  token = null
) => {
  return await ApiWrapper({
    url: `cost_calculators/widget?school_id=${school_id}`,
    method: REQUEST.GET,
    ...(token ? { config: { headers: { token } } } : {}),
  });
};

export const getWidgetScholarships = async (school_id = null, token = null) => {
  return await ApiWrapper({
    url: `scholarships/widget?school_id=${school_id}`,
    method: REQUEST.GET,
    ...(token ? { config: { headers: { token } } } : {}),
  });
};

export const getWidgetEmploymentData = async (token = null) => {
  return await ApiWrapper({
    url: `opts/widget/listing`,
    method: REQUEST.GET,
    ...(token ? { config: { headers: { token } } } : {}),
  });
};
