import { useEffect } from "react";
import ActionCable from "actioncable";
import { useSelector, useDispatch } from "react-redux";

import { SOCKET_URL } from "../config/config";
import {
  setConversationChannel,
  setNotificationChannel,
  setConversationReactionsChannel,
} from "../redux/reducers/webSocketReducer";
import {
  webSocketActionRecevied,
  setSocketReactionsConnectionStatus,
  processWebsocketReactionsMessage,
} from "../redux/reducers/chat/reducers";
import {
  setAllNotificationCounts,
  setLiveChatNotificationCounts,
  setLiveTopicNotificationCounts,
} from "../redux/reducers/notificationReducer";
import { fetchAllNotificationCount } from "../api/notification";

/**
 * This provider component connects with webSocket connection and subscribes to different available channels
 * References to subscribed channels are stored in redux store and made available to app
 * @returns null
 */
export default function Websocket() {
  const { isLogin, user } = useSelector((store) => store.userState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLogin) return;

    console.log("Websocket provider executed");
    // Create websocket connection
    let cable = ActionCable.createConsumer(`${SOCKET_URL}${user.id}`);

    // Call All Notification Count API
    (async () => {
      try {
        const response = await fetchAllNotificationCount();
        if (response.success) {
          dispatch(setAllNotificationCounts(response.data));
        }
      } catch (error) {
        console.error(error);
      }
    })();

    // Channel subscription
    const chatChannel = cable.subscriptions.create(
      { channel: "ConversationChannel" },
      {
        connected: () => {
          console.log("WebSocket: connected to ConversationChannel");
        },
        received: (data) => {
          console.log("WebSocket broadcast: ", data);
          dispatch(webSocketActionRecevied({ broadcastData: data }));
        },
        create: function (chatContent) {
          console.log("FLAG message speak payload: ", chatContent);
          this.perform("speak", {
            content: chatContent,
          });
        },
      }
    );

    // Channel subscription
    const notificationChannel = cable.subscriptions.create(
      { channel: "NotificationChannel" },
      {
        connected: () => {
          console.log("WebSocket: connected to NotificationChannel");
        },
        received: (data) => {
          console.log(
            "WebSocket: received on NotificationChannel -> ",
            JSON.stringify(data)
          );
          if (data) {
            if (data.notification_type === "topic")
              dispatch(setLiveTopicNotificationCounts(data));
            else if (data.notification_type === "chat")
              dispatch(setLiveChatNotificationCounts(data));
          }
        },
        disconnected: () => {
          console.log("WebSocket: disconnected from NotificationChannel");
        },
      }
    );

    // Channel subscription
    const conversationReactionsChannel = cable.subscriptions.create(
      {
        channel: "ReactionChannel",
      },
      {
        connected: () => {
          dispatch(setSocketReactionsConnectionStatus(true));
          console.log(
            "MSG Socket Conversation Reactions Channel: Status Connected"
          );
        },
        received: (data) => {
          console.log(
            "MSG Socket Conversation Reactions Channel - Broadcast: ",
            data
          );
          dispatch(processWebsocketReactionsMessage(data));
        },
        message: function (reactionContent) {
          this.perform("receive", reactionContent);
          console.log(
            "MSG Socket Conversation Reactions Channel - Message Payload: ",
            reactionContent
          );
        },
        disconnected: () => {
          dispatch(setSocketReactionsConnectionStatus(false));
          console.log(
            "MSG Socket Conversation Reactions Channel: Status Disconnected"
          );
        },
      }
    );

    // Update store with socket channel reference
    dispatch(setConversationChannel(chatChannel));
    dispatch(setNotificationChannel(notificationChannel));
    dispatch(setConversationReactionsChannel(conversationReactionsChannel));

    return () => {
      cable?.disconnect && cable.disconnect();
      dispatch(setSocketReactionsConnectionStatus(false));
      console.log("Websocket Disconnected");
    };
  }, [isLogin, user.id, dispatch]);

  return null;
}
