import React, { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";

import MatchMeCard from "../../atoms/MatchMeCard/MatchMeCard";
import ScrollToTopIcon from "../../atoms/ScrollToTopIcon/ScrollToTopIcon";
import DashboardChecklistLink from "../../molecules/DashboardChecklistLink/DashboardChecklistLink";
import DashboardSavedPostCard from "../../molecules/DashboardSavedPostCard/DashboardSavedPostCard";
import DashboardUserProfileCard from "../../molecules/DashboardUserProfileCard/DashboardUserProfileCard";
import DashboardUpcomingWebinars from "../../molecules/DashboardUpcomingWebinars/DashboardUpcomingWebinars";
import DashboardPostsList from "../../molecules/DashboardPostsList/DashboardPostsList";
import DashboardInviteFriend from "../../molecules/DashboardInviteFriend/DashboardInviteFriend";
import SavedPostsList from "../../molecules/SavedPostsList/SavedPostsList";
import DashboardLinks from "../../molecules/DashboardLinks/DashboardLinks";
import Footer from "../../molecules/Footer/Footer";
import DashboardPromoteSlider from "../../molecules/DashboardPromoteSlider/DashboardPromoteSlider";
import MatchAmbassador from "../../molecules/MatchAmbassador/MatchAmbassador";

import { getSavedPosts } from "../../../api/dashboardPosts";
import { getRecommendedAmbassadors } from "../../../api/chat";
import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import { createModuleStyleExtractor } from "../../../utils/css";
import toastify from "../../../utils/toast";
import styles from "./Dashboard.module.scss";

const cx = createModuleStyleExtractor(styles);

const Dashboard = () => {
  const {
    isProspect,
    isParent,
    permissions = {},
    selectedSchool = {},
  } = useSelector((state) => state.userState);
  const selectedSchoolApplyLink = selectedSchool?.apply_link;

  const [page, setPage] = useState("Dashboard");
  const [savedPostCount, setSavedPostsCount] = useState(0);
  const [showFooter, setShowFooter] = useState(false);
  const [matchMeModal, setMatchMeModal] = useState({
    open: false,
    loadingAPI: false,
    ambassadors: [],
  });

  const fetchSavedPosts = async () => {
    const response = await getSavedPosts();
    if (response.success) {
      setSavedPostsCount(response.data.total_saved_posts);
    } else {
      setSavedPostsCount(0);
    }
  };

  const handleMatchMe = async () => {
    setMatchMeModal({
      open: false,
      loadingAPI: true,
      ambassadors: [],
    });
    const response = await getRecommendedAmbassadors(selectedSchool?.id);
    if (response.success) {
      if (response?.data?.recommended_network?.length > 0) {
        setMatchMeModal({
          open: true,
          loadingAPI: false,
          ambassadors: response?.data?.recommended_network || [],
        });
      } else {
        setMatchMeModal({
          open: false,
          loadingAPI: false,
          ambassadors: [],
        });
        toastify("No recommended ambassadors found.", "error");
      }
    } else {
      toastify(response.message, "error");
      setMatchMeModal({
        open: false,
        loadingAPI: false,
        ambassadors: [],
      });
    }
  };

  useEffect(() => {
    fetchSavedPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showNetworkCard = selectedSchoolApplyLink || permissions["chat"];
  const showWebinarsCard = (isProspect || isParent) && permissions["webinar"];
  const showRightSection = showNetworkCard || showWebinarsCard;

  return (
    <>
      {page === "SavedPosts" ? (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <SavedPostsList
              setPage={() => {
                setPage("Dashboard");
                fetchSavedPosts();
              }}
            />
          </Col>
        </Row>
      ) : (
        <div className={cx("o-dashboard-container")}>
          <div className={cx("o-dashboard-container__back-section")}>
            <div className={cx("o-dashboard-container__main-section")}>
              <Row>
                <Col xs={12} sm={3} md={3} lg={3}>
                  <DashboardUserProfileCard />
                  {(isProspect || isParent) && permissions["checklist"] && (
                    <DashboardChecklistLink />
                  )}
                  <DashboardSavedPostCard
                    count={savedPostCount}
                    setPage={() => setPage("SavedPosts")}
                  />
                  <div className="ShowResponsiveDiv">
                    <DashboardPromoteSlider />
                    <DashboardInviteFriend />
                    {showNetworkCard ? (
                      <div className="mb-15">
                        <MatchMeCard
                          matchMeModal={matchMeModal}
                          handleMatchMe={handleMatchMe}
                          variant="dashboard"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {showWebinarsCard && <DashboardUpcomingWebinars />}
                  </div>
                  <DashboardLinks setShowFooter={setShowFooter} />
                </Col>
                <Col
                  xs={12}
                  sm={showRightSection ? 6 : 9}
                  md={showRightSection ? 6 : 9}
                  lg={showRightSection ? 6 : 9}
                >
                  <DashboardPostsList refreshSavedPosts={fetchSavedPosts} />
                  <ScrollToTopIcon />
                </Col>
                {showRightSection && (
                  <Col xs={12} sm={3} md={3} lg={3}>
                    <DashboardPromoteSlider />
                    <DashboardInviteFriend />
                    {showNetworkCard ? (
                      <div className="mb-15">
                        <MatchMeCard
                          matchMeModal={matchMeModal}
                          handleMatchMe={handleMatchMe}
                          variant="dashboard"
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {showWebinarsCard && (
                      <DashboardUpcomingWebinars showCard={!showNetworkCard} />
                    )}
                  </Col>
                )}
              </Row>
              {showFooter && <Footer setShowFooter={setShowFooter} />}
            </div>
          </div>
        </div>
      )}
      {showNetworkCard ? (
        <MatchAmbassador
          matchMeModal={matchMeModal}
          setMatchMeModal={setMatchMeModal}
          selectedSchoolId={selectedSchool?.id}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default memo(Dashboard);
