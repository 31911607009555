import React, { useState, useRef, useEffect, useReducer, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmojiPicker, { Categories, EmojiStyle } from "emoji-picker-react";
import { ClickAwayListener } from "@mui/material";

import { reducer, defaultState } from ".";
import {
  setIsAttachment,
  setIsMessageSending,
} from "../../../redux/reducers/chat/reducers";
import { isSending } from "../../../redux/reducers/chat/selectors";
import { validateChatAttachment } from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { Spinner } from "../../atoms/Spinner/Spinner";
import styles from "./ChatTextBox.module.scss";

import Attachment from "../../../assets/Icons/Attachment";
import EmojiPickerIcon from "../../../assets/Icons/EmojiPickerIcon";
import deleteIcon from "../../../assets/images/f-delete-icon.svg";

const cx = createModuleStyleExtractor(styles);

const ChatTextBox = React.forwardRef(
  ({ className, styles, onSubmit, disabled = false }, callbackRef) => {
    const dispatch = useDispatch();

    const [state, _dispatch] = useReducer(reducer, defaultState);

    const { attachment, message, emojiContainer } = state;

    const selectedInputFile = useRef(null);
    const [updatedHeight, setUpdatedHeight] = useState({
      ...styles,
    });
    const messageSending = useSelector((state) => isSending(state.chatStore));

    useEffect(() => {
      if (styles) {
        setUpdatedHeight({
          ...updatedHeight,
          ...styles,
        });
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [styles]);

    /**
     * Once the emoji is selected
     * @param {*} event
     * @param {*} emojiObject
     */
    // const onEmojiClick = (event, emojiObject) => {
    //   _dispatch({
    //     type: "bulkUpdate",
    //     value: {
    //       chosenEmoji: emojiObject,
    //       message: `${message}${emojiObject.emoji}`,
    //       emojiContainer: !emojiContainer,
    //     },
    //   });
    // };

    /**
     * Called on File select
     */
    const onFileSelectButtonClick = () => {
      if (selectedInputFile) {
        selectedInputFile.current.click();
      }
    };

    /**
     * Onchange handler for the file
     * @param {*} e
     * @returns
     */
    const onFileChange = (e) => {
      const file = e.target.files[0];
      const attachment = validateChatAttachment(file);
      if (attachment !== 200) {
        selectedInputFile.current.value = ""; // Clear Input
        return;
      }
      _dispatch({
        type: "state.mutate",
        key: "attachment",
        value: {
          name: file.name,
          content: file,
        },
      });
      dispatch(setIsAttachment(true));
    };

    /**
     * Call once the message is submitted
     * @returns
     */
    const onSubmitText = () => {
      if (messageSending) return;

      if (!message && !attachment) return;

      var isValidAction = false;
      if (message || attachment) {
        isValidAction = true;
      } else {
        if (!attachment) {
          if (message.trim() !== "") isValidAction = true;
          isValidAction = false;
        }
      }

      if (!attachment) {
        if (message.trim() === "") return;
      }
      if (isValidAction) {
        callbackRef.current = _dispatch({ type: "state.reset" });
        onSubmit(message.trim() === "" ? "" : message, attachment);
        dispatch(setIsMessageSending(true));
        selectedInputFile.current.value = ""; // Clear Input
      }
    };

    useEffect(() => {
      if (!messageSending) {
        _dispatch({
          type: "bulkUpdate",
          value: { message: "", attachment: null },
        });
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageSending]);
    /**
     * Handler on Enter press
     * @param {*} e
     */
    const onEnterPress = (e) => {
      if (messageSending) return;
      if (disabled) return;
      if (e.keyCode === 13 && e.shiftKey === false) {
        e?.preventDefault && e.preventDefault();
        onSubmitText();
      }
    };

    const onChangeHandlerText = (e) => {
      _dispatch({
        type: "state.mutate",
        key: "message",
        value:
          e.key === "Enter" && e.shiftKey
            ? `${e.target.value}\\n`
            : e.target.value,
      });
    };

    const onEmojiPick = (emoji = {}) => {
      _dispatch({
        type: "state.appendEmoji",
        value: emoji?.emoji || "",
      });
    };

    const handleRemoveAttachment = () => {
      _dispatch({
        type: "state.mutate",
        key: "attachment",
        value: "",
      });
      dispatch(setIsAttachment(false));
      selectedInputFile.current.value = ""; // Clear Input
    };
    return (
      <>
        <div
          className={cx(["chat-textbox", ...(className ? [className] : [])])}
        >
          {messageSending && (
            <div style={{ position: "absolute", right: "25px" }}>
              <Spinner />
            </div>
          )}
          <input
            id="_upload-file_"
            type="file"
            ref={selectedInputFile}
            onChange={onFileChange}
            className={cx("hidden-input")}
          />

          <textarea
            disabled={disabled}
            placeholder="Write a message"
            value={message || ""}
            onChange={(e) => onChangeHandlerText(e)}
            onKeyDown={onEnterPress}
            style={{
              ...updatedHeight,
            }}
            className={cx("chat-input-textarea")}
          ></textarea>
          <div className={cx("chat-textbox__actions")}>
            {attachment && (
              <div className={cx("chat-attachments")}>
                <div className={cx("chat-attachments__item")}>
                  <span className={cx("file")}> {attachment.name} </span>
                  <span className={cx("icon")}>
                    <img
                      src={deleteIcon}
                      onClick={() => handleRemoveAttachment()}
                      alt="delete"
                    />
                  </span>
                </div>
              </div>
            )}
            <div className={cx(["chat-textbox__actions--icon"])}>
              <Attachment
                styles={{ cursor: disabled ? "not-allowed" : "pointer" }}
                onClick={() => !disabled && onFileSelectButtonClick()}
              />
            </div>

            <ClickAwayListener
              onClickAway={() => {
                _dispatch({
                  type: "state.mutate",
                  key: "emojiContainer",
                  value: false,
                });
              }}
            >
              <div className={cx("chat-textbox__actions--icon")}>
                <EmojiPickerIcon
                  onClick={() => {
                    _dispatch({
                      type: "state.mutate",
                      key: "emojiContainer",
                      value: !emojiContainer,
                    });
                  }}
                />
                <div
                  className={cx("chat-textbox__actions--icon--picker-wrapper")}
                  style={{ display: emojiContainer ? "block" : "none" }}
                >
                  <EmojiPicker
                    emojiVersion={15.0}
                    previewConfig={{ showPreview: false }}
                    onEmojiClick={onEmojiPick}
                    emojiStyle={EmojiStyle.GOOGLE}
                    width="100%"
                    height="320px"
                    categories={[
                      {
                        name: "Frequently Used",
                        category: Categories.SUGGESTED,
                      },
                      {
                        name: "Smileys & People",
                        category: Categories.SMILEYS_PEOPLE,
                      },
                      {
                        name: "Animals & Nature",
                        category: Categories.ANIMALS_NATURE,
                      },
                      {
                        name: "Food & Drink",
                        category: Categories.FOOD_DRINK,
                      },
                      {
                        name: "Travel & Places",
                        category: Categories.TRAVEL_PLACES,
                      },
                      {
                        name: "Activities",
                        category: Categories.ACTIVITIES,
                      },
                      {
                        name: "Objects",
                        category: Categories.OBJECTS,
                      },
                      {
                        name: "Symbols",
                        category: Categories.SYMBOLS,
                      },
                      {
                        name: "Flags",
                        category: Categories.FLAGS,
                      },
                    ]}
                  />
                </div>
              </div>
            </ClickAwayListener>
            <div className={cx("chat-textbox__actions--send-button")}>
              <Button
                disabled={messageSending || disabled}
                onClick={(e) => onSubmitText()}
                type={"button"}
                modifier={
                  disabled
                    ? ButtonModifier.CHAT_SEND_BUTTON_DISABLED
                    : ButtonModifier.CHAT_SEND_BUTTON
                }
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default memo(ChatTextBox);
