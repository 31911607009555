import React, { memo, useState, useEffect } from "react";
import { ShimmerSocialPost } from "react-shimmer-effects";
import InfiniteScroll from "react-infinite-scroll-component";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./SavedPostsList.module.scss";
import { getSavedPosts } from "../../../api/dashboardPosts";
import DashboardPost from "../../molecules/DashboardPost/DashboardPost";
import { Row } from "../../helpers/Grid/Row";
import { Col } from "../../helpers/Grid/Col";
import { setDocumentTitle } from "../../../utils/common";

import EmptyPostIcon from "../../../assets/Icons/EmptyPostIcon";
import BackIcon from "../../../assets/images/f-btn-arrow-gray.svg";

const cx = createModuleStyleExtractor(styles);

const SavedPostsList = ({ setPage = () => {} }) => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);

  const fetchSavedPosts = async (page) => {
    const response = await getSavedPosts(page);
    if (response.success) {
      setPosts(
        page === 1
          ? [...response.data.saved_posts]
          : [...posts, ...response.data.saved_posts]
      );
      setTotalPages(response.data.total_pages);
      if (currentPage === 0) setCurrentPage(1);
      setLoader(false);
    } else {
      setPosts([]);
      setLoader(false);
    }
  };

  useEffect(() => {
    setDocumentTitle("Saved Posts");
    setLoader(true);
    fetchSavedPosts(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchMoreData = () => {
    setCurrentPage(currentPage + 1);
    fetchSavedPosts(currentPage + 1);
  };

  const refreshSavedPosts = () => {
    setLoader(true);
    fetchSavedPosts(1);
  };

  const PostsLoader = () => (
    <Row>
      <Col xs={12} sm={12} md={6} lg={6}>
        <ShimmerSocialPost type="image" key={1} />
      </Col>
      <Col xs={12} sm={12} md={6} lg={6}>
        <ShimmerSocialPost type="image" key={2} />
      </Col>
    </Row>
  );

  return (
    <div className={cx("m-saved-post-list-container")}>
      <div className={cx("m-saved-post-list-container__header")}>
        <a
          href="/dashboard"
          className={cx("m-saved-post-list-container__header--back-btn")}
          onClick={(e) => {
            e?.preventDefault && e.preventDefault();
            setPage();
          }}
        >
          <img src={BackIcon} id="BackArrow" width="18px" alt="Back arrow" />{" "}
          <span>Back to Dashboard</span>
        </a>
        <h2 className={cx("m-saved-post-list-container__header--title")}>
          Your Saved Posts
        </h2>
      </div>
      {loader ? (
        <PostsLoader />
      ) : (
        <>
          {posts.length === 0 ? (
            <div className={cx("m-saved-post-list-container__empty")}>
              <EmptyPostIcon />
              <h2>Looks like you don’t have any saved posts here.</h2>
              <h3>
                To save a post, click on the “More” icon on the top right of a
                post and select “Save post”.
              </h3>
            </div>
          ) : (
            <InfiniteScroll
              dataLength={posts.length}
              next={fetchMoreData}
              hasMore={currentPage !== totalPages && totalPages > 0}
              loader={<PostsLoader />}
              style={{ overflow: "none" }}
              endMessage={
                <div>{currentPage === 0 ? <PostsLoader /> : <></>}</div>
              }
            >
              <div
                className={cx(
                  "m-saved-post-list-container__dashboard-saved-content"
                )}
              >
                {posts.map((post, index) => (
                  <DashboardPost
                    post={post}
                    key={`saved-post-${index}`}
                    index={`saved-post-${index}`}
                    isFavorite={true}
                    refreshSavedPosts={refreshSavedPosts}
                  />
                ))}
              </div>
            </InfiniteScroll>
          )}
        </>
      )}
    </div>
  );
};
export default memo(SavedPostsList);
