import React, { memo, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Tabs, Tab, Box } from "@mui/material";

import { createModuleStyleExtractor } from "../../../utils/css";
import { validateWidgetToken } from "../../../api/widget";
import { DEFAULT_PRIMARY_COLOR } from "../../../config/config";
import { setThemeColor } from "../../../redux/reducers/preservedReducer";
import { ROUTE_TYPES, setBodyBackground } from "../../../utils/common";
import styles from "./UniversityWidget.module.scss";
import InterstridePageLoader from "../../atoms/InterstridePageLoader/InterstridePageLoader";
import WidgetStudentsIcon from "../../../assets/Icons/WidgetStudentsIcon";
import WidgetMessagesIcon from "../../../assets/Icons/WidgetMessagesIcon";
import WidgetResourcesIcon from "../../../assets/Icons/WidgetResourcesIcon";
import WidgetChatsTab from "../../molecules/WidgetChtasTab/WidgetChatsTab";
import WidgetCommunityTab from "../../molecules/WidgetCommunityTab/WidgetCommunityTab";
import WidgetResourcesTab from "../../molecules/WidgetResourcesTab/WidgetResourcesTab";
import { INTERSTRIDE_LOGO_URL } from "../../../utils/constants";
import WidgetEmployment from "../../molecules/WidgetEmployment/WidgetEmployment";
import WidgetSearchIcon from "../../../assets/Icons/WidgetSearchIcon";

const cx = createModuleStyleExtractor(styles);

const UniversityWidget = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const universityToken = params.university_token
    ? params.university_token
    : false;

  const [schoolData, setSchoolData] = useState({});
  const [showLoader, setShowLoader] = useState(universityToken ? true : false);
  const [showInvalidToken, setShowInvalidToken] = useState(false);
  const [selectedTab, setSelectedTab] = useState();
  const [resetChatViewStates, setResetChatViewStates] = useState(false);

  const { school_module_permission = {} } = schoolData;

  useEffect(() => {
    setBodyBackground("transparent");
  }, []);

  useEffect(() => {
    const checkUniversityToken = async () => {
      const response = await validateWidgetToken(universityToken);

      if (response.success && response.data) {
        const {
          data: { school = {}, school_module_permission = {} },
        } = response;

        const themeColor =
          school && school.theme_color
            ? school.theme_color
            : DEFAULT_PRIMARY_COLOR;
        dispatch(setThemeColor(themeColor));
        setSchoolData({
          ...school,
          token: universityToken,
          school_module_permission,
        });
        setShowLoader(false);
        setSelectedTab(tabsList[0].value);
      } else {
        // Show invalid token page
        setShowInvalidToken(true);
        setShowLoader(false);
      }
    };
    if (universityToken) {
      checkUniversityToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universityToken, dispatch]);

  const resourceSubTabs = useMemo(() => {
    return [
      {
        key: "webinars",
        value: "Webinars",
        route_type: ROUTE_TYPES.WEBINAR,
        signup_overlay: true,
        permission: school_module_permission["webinar"],
        title: "Join webinars to get ahead of the game",
      },
      {
        key: "courses",
        value: "Courses",
        route_type: ROUTE_TYPES.COURSE,
        signup_overlay: true,
        permission: school_module_permission["courses"],
        title: "The ultimate guide to US admissions",
      },
      {
        key: "services",
        value: "Services",
        route_type: ROUTE_TYPES.SERVICE,
        signup_overlay: true,
        permission: school_module_permission["student_services"],
        title: "Get insider knowledge",
      },
      {
        key: "resources",
        value: "Resources",
        route_type: ROUTE_TYPES.RESOURCE,
        signup_overlay: true,
        permission: school_module_permission["resources"],
        title: "Get insider knowledge",
      },
    ].filter((rec) => rec.permission);
  }, [school_module_permission]);

  const tabsList = [
    {
      value: "Chat",
      icon: <WidgetMessagesIcon />,
      content: (
        <WidgetChatsTab
          schoolData={schoolData}
          selectedTab={selectedTab}
          resetChatViewStates={resetChatViewStates}
          setResetChatViewStates={setResetChatViewStates}
        />
      ),
      permission: true,
    },
    {
      // Set title of the sub-tab if only one sub-tab has feature permission
      value:
        resourceSubTabs.length === 1 ? resourceSubTabs[0].value : "Resources",
      icon: <WidgetResourcesIcon />,
      content: (
        <WidgetResourcesTab
          schoolData={schoolData}
          resourceSubTabs={resourceSubTabs}
        />
      ),
      permission: resourceSubTabs.length > 0,
    },
    {
      value: "Community",
      icon: <WidgetStudentsIcon />,
      content: <WidgetCommunityTab schoolData={schoolData} />,
      permission: schoolData?.school_module_permission?.topics,
    },
    {
      value: "University Insights",
      icon: <WidgetSearchIcon />,
      content: <WidgetEmployment schoolData={schoolData} />,
      permission:
        (schoolData?.school_module_permission?.["employment_data"] &&
          schoolData?.school_module_permission?.["opt_upload"]) ||
        schoolData?.school_module_permission?.["cost_calculator"] ||
        (schoolData?.school_module_permission?.["campus_map"] &&
          schoolData?.school_module_permission?.["campus_map_data_uploaded"]),
    },
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleTabChangeSideEffects = (value = null) => {
    if (value === tabsList[0]?.value) {
      setResetChatViewStates(true);
    }
  };

  return (
    <>
      {showLoader ? (
        <InterstridePageLoader />
      ) : showInvalidToken ? (
        <div className={cx("o-university-widget")}>
          <div className={cx("o-university-widget__invalid-token")}>
            <div className={cx("o-university-widget__invalid-token__content")}>
              <div
                className={cx(
                  "o-university-widget__invalid-token__content__sitelogo"
                )}
              >
                <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
              </div>
              <h2>
                Woops!
                <br /> We couldn't find the resource you are looking for!
              </h2>
            </div>
            <h6>
              If you have any questions, please reach out to us at{" "}
              <a href="mailto:contact@interstride.com">
                contact@interstride.com.
              </a>
            </h6>
          </div>
        </div>
      ) : (
        <div className="widget-wrapper">
          <div className={cx("o-university-widget")}>
            <div className="o-university-widget-wrapper">
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <div className="o-university-widget-tabs">
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      centered
                    >
                      {tabsList.map(
                        ({ value, icon, permission }, index) =>
                          permission && (
                            <Tab
                              key={index}
                              value={value}
                              id={`tab-${value}`}
                              aria-controls={`tabpanel-${value}`}
                              onClick={() => handleTabChangeSideEffects(value)}
                              label={
                                <div
                                  className={cx([
                                    "o-university-widget__tab-header",
                                    selectedTab === value
                                      ? "o-university-widget__tab-header--active"
                                      : "",
                                  ])}
                                >
                                  {icon}
                                  <span
                                    className={cx(
                                      "o-university-widget__tab-header__title"
                                    )}
                                  >
                                    {value}
                                  </span>
                                </div>
                              }
                            />
                          )
                      )}
                    </Tabs>
                  </div>
                </Box>

                {tabsList.map(({ value, content = <div></div> }, index) => (
                  <div
                    key={index}
                    role="tabpanel"
                    hidden={selectedTab !== value}
                    id={`tab-content-${value}`}
                    aria-labelledby={`tab-content-panel-${value}`}
                    className={cx([
                      "o-university-widget__tab-content",
                      selectedTab === value
                        ? "o-university-widget__tab-content--active"
                        : "",
                    ])}
                  >
                    {selectedTab === value && <div>{content}</div>}

                    <div
                      className={cx(
                        "o-university-widget__tab-content__footer-branding"
                      )}
                    >
                      <h3>Powered by</h3>
                      <img src={INTERSTRIDE_LOGO_URL} alt="interstride logo" />
                    </div>
                  </div>
                ))}
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default memo(UniversityWidget);
