import React, { Fragment, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import Picker from "emoji-picker-react";
import { ClickAwayListener } from "@mui/material";

import { SingleMessageItemV2 } from "../SingleMessageItemV2/SingleMessageItemV2";
import styles from "./Messages.module.scss";
import {
  getChatPreviewByConversationId,
  getConversationByConversationID,
} from "../../../redux/reducers/chat/selectors";
import {
  updateChatsUnreadCount,
  setCurrentSearchMessageItem,
} from "../../../redux/reducers/chat/reducers";
import { usePaginateConversation } from "../../../hooks/usePaginateConversation";
import { createModuleStyleExtractor } from "../../../utils/css";
import { RequestMeeting } from "../RequestMeeting/RequestMeeting";
import { deliveryTime } from "../../../utils/common";

const cx = createModuleStyleExtractor(styles);
const recentMessageInterval = 60000; // Time in milliseconds between two messages which are considered recent messages
const requestMeetingBannerOpacity = "10"; // Amount in percentage of opacity

const Messages = React.forwardRef(
  ({ messages, loading, generateConversationTitle = () => {} }, ref) => {
    const dispatch = useDispatch();
    const conversationTitle = generateConversationTitle();

    const { userId } = useSelector((state) => state.userState);
    const chatStore = useSelector((state) => state.chatStore);
    const { themeColor } = useSelector((state) => state.preservedState);
    const { activeConversation } = chatStore || {};
    const conversation = useSelector((state) =>
      getConversationByConversationID(state.chatStore)
    );
    const { conversationReactionsChannel } = useSelector(
      (store) => store.webSocketStore
    );

    const { next_page, current_page } = conversation || {};
    const [options, setOptions] = useState(null);
    const [requestMeetingState, setRequestMeetingState] = useState({
      open: false,
      message: null,
    });
    const [reactionsEmojiContainer, setReactionsEmojiContainer] = useState({
      open: false,
      messageId: null,
      isReactionsState: true,
    });

    const { pagination } = usePaginateConversation(activeConversation, options);
    const preview = useSelector((state) =>
      getChatPreviewByConversationId(state.chatStore)
    );

    const textDeliveredToday = messages
      ? messages.find(
          (message) =>
            new Date(message.send_at).setHours(0, 0, 0, 0) >=
            new Date().setHours(0, 0, 0, 0)
        )
      : null;

    const scrollToTheBottom = () => {
      /**
       * block: nearest prevents entire page from scrolling and
       * focuses on the last element marked for scrolling inside the chat box
       */
      ref.current.scrollIntoView({ behavior: "smooth" });

      /**
       * if reach to the bottom and conversation is active then update the unread count
       */
      if (!preview) return;
      preview.count > 0 &&
        dispatch(
          updateChatsUnreadCount({
            conversation_id: preview.conversation_id,
          })
        );
    };

    /**
     * Helps to do pagination stuff
     */
    const paginateMessages = () => {
      if (next_page) {
        dispatch(setCurrentSearchMessageItem(null));
        setOptions({
          id_lt: messages[0]?.id,
          page: next_page,
        });
      }
    };
    useEffect(() => {
      if (ref.current && current_page === 1 && messages?.length > 0 && !loading)
        setTimeout(() => {
          scrollToTheBottom();
        }, 1000);

      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages, loading]);

    useEffect(() => {
      //if conversation Id changes, then we need to reset the current option state
      setOptions(null);
      // Reset request meeting state
      setRequestMeetingState({
        open: false,
        message: null,
      });
      //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeConversation]);

    /**
     * Instead of doing computation inside dom
     * We will do it here and use useMemo for performance optimization
     * @returns
     */
    const passthroughChatEngine = () => {
      const _dateCache = {};
      const _timeCache = {};

      return messages.map((message, index) => {
        const group =
          message.sender_id ===
          messages[index > 0 ? index - 1 : index]?.sender_id;
        const currentDate = new Date(message.send_at)
          .setHours(0, 0, 0, 0)
          .toString();
        const currentTime = new Date(message.send_at)
          .setSeconds(0, 0)
          .toString();

        let groupByDate = false;
        let groupByTime = false;

        if (!_dateCache[currentDate]) {
          groupByDate = true;
          _dateCache[currentDate] = true;
        }

        if (!_timeCache[currentTime]) {
          groupByTime = true;
          _timeCache[currentTime] = true;
        }

        return {
          ...message,
          groupByDate,
          groupByTime,
          groupByUser: group,
        };
      });
    };

    const chat_messages = passthroughChatEngine();

    /**
     * Calculates if a current message is recent
     * @param {int} index position of current message
     * @returns {boolean} Status
     */
    const isRecentMessage = (index, currentDate) => {
      // Check if previous message exist
      if (chat_messages[index - 1] === undefined) return false;

      // Check if previous message belongs to request meeting
      if (chat_messages[index - 1]?.conversation_type === "request_meeting")
        return false;

      // Check if previous message of the same user
      if (chat_messages[index - 1].sender_id !== chat_messages[index].sender_id)
        return false;

      // Compute recent message status
      return (
        currentDate - new Date(chat_messages[index - 1].send_at) <
        recentMessageInterval
      );
    };

    const handleReaction = async (emojiData = {}, id) => {
      // Call socket method
      conversationReactionsChannel?.message &&
        conversationReactionsChannel.message({
          emoji: emojiData?.emoji,
          emoji_unicode:
            emojiData?.unifiedWithoutSkinTone || emojiData?.emoji_unicode,
          message_id: id,
        });
      // Close emoji picker
      reactionsEmojiContainer.open &&
        setReactionsEmojiContainer({
          open: false,
          messageId: null,
          isReactionsState: true,
        });
    };

    return (
      <>
        {reactionsEmojiContainer.open &&
          reactionsEmojiContainer.messageId &&
          !reactionsEmojiContainer.isReactionsState && (
            <ClickAwayListener
              onClickAway={() =>
                setReactionsEmojiContainer({
                  open: false,
                  messageId: null,
                  isReactionsState: true,
                })
              }
            >
              <div className={cx("messages-container__emoji-picker")}>
                <Picker
                  onEmojiClick={(e) =>
                    handleReaction(e, reactionsEmojiContainer.messageId)
                  }
                  skinTonesDisabled
                  width="97%"
                  previewConfig={{ showPreview: false }}
                />
              </div>
            </ClickAwayListener>
          )}
        <div
          className={cx([
            "messages-container",
            !messages || messages?.length === 0
              ? "messages-container__fresh"
              : "",
          ])}
          id={"student-chat-portal__messages-container"}
        >
          <div className={cx("messages-container__row")}>
            {loading || pagination ? (
              <div className={cx("messages-container__loader")}>
                {Array.from(Array(pagination ? 4 : 5).keys()).map(
                  (_, index) => (
                    <div
                      className={cx("messages-container__loader__item")}
                      key={index}
                    >
                      <ShimmerCategoryItem
                        hasImage
                        imageType="circular"
                        imageWidth={60}
                        imageHeight={60}
                        text
                      />
                    </div>
                  )
                )}
              </div>
            ) : (
              <>
                {next_page && (
                  <span className={cx(["divider", "show-older-messages"])}>
                    <span
                      className={cx("date")}
                      onClick={() => paginateMessages()}
                    >
                      Show older messages
                    </span>
                  </span>
                )}
                {chat_messages?.map((message, index) => {
                  const currentDate = new Date(message.send_at);
                  const isRecent = isRecentMessage(index, currentDate);
                  const isOwnMessage = userId === message?.sender_id;
                  return (
                    <Fragment key={message?.id || index}>
                      {
                        <>
                          {textDeliveredToday &&
                          textDeliveredToday?.id === message?.id ? (
                            <div className={cx("divider")}>
                              <span className={cx("date")}>Today</span>
                            </div>
                          ) : (
                            message?.groupByDate && (
                              <div className={cx("divider")}>
                                <span className={cx(["date", "groupByDate"])}>
                                  {`${currentDate.toLocaleString("default", {
                                    month: "long",
                                  })} ${currentDate.toLocaleDateString(
                                    "default",
                                    {
                                      day: "2-digit",
                                    }
                                  )}, ${currentDate.getFullYear()}`}
                                </span>
                              </div>
                            )
                          )}
                        </>
                      }
                      {message?.conversation_type === "request_meeting" ? (
                        <div
                          className={cx("messages-container__request-meeting")}
                        >
                          {!isRecent && (
                            <div
                              className={cx([
                                "messages-container__request-meeting__time",
                                isOwnMessage
                                  ? "messages-container__request-meeting__time__own"
                                  : "",
                              ])}
                            >
                              {isOwnMessage ? (
                                <span>
                                  You at {deliveryTime(message?.send_at)}
                                </span>
                              ) : (
                                <span>
                                  {message?.sender_name?.split(" ")[0]} at{" "}
                                  {deliveryTime(message?.send_at)}
                                </span>
                              )}
                            </div>
                          )}
                          <div
                            className={cx(
                              "messages-container__request-meeting__banner"
                            )}
                            style={{
                              background:
                                themeColor + requestMeetingBannerOpacity, // Dyanmically add opaque color
                            }}
                          >
                            {isOwnMessage ? (
                              <span>
                                You have requested a meeting
                                {conversationTitle?.length > 0 &&
                                  " with " + conversationTitle}
                              </span>
                            ) : (
                              <span>
                                {conversationTitle?.length > 0
                                  ? conversationTitle
                                  : "User"}{" "}
                                requested a meeting with you
                              </span>
                            )}
                            <span
                              onClick={() =>
                                setRequestMeetingState({
                                  open: true,
                                  message: message?.message,
                                })
                              }
                            >
                              View message
                            </span>
                          </div>
                        </div>
                      ) : (
                        <SingleMessageItemV2
                          item={message}
                          isRecent={isRecent}
                          reactionsEmojiContainer={reactionsEmojiContainer}
                          setReactionsEmojiContainer={
                            setReactionsEmojiContainer
                          }
                          handleReaction={handleReaction}
                        />
                      )}
                    </Fragment>
                  );
                })}
                {/* Use the following empty div to scroll chat into view */}
                <div ref={ref} />
              </>
            )}
          </div>
          <RequestMeeting
            show={requestMeetingState.open}
            user={conversation?.user_info}
            handleClose={() =>
              setRequestMeetingState({
                open: false,
                message: null,
              })
            }
            variant="messages"
            meetingMessage={requestMeetingState.message}
          />
        </div>
      </>
    );
  }
);
export default memo(Messages);
