import React, { memo, useState, useEffect } from "react";
import { ShimmerThumbnail } from "react-shimmer-effects";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./WidgetEmployment.module.scss";
import {
  createFriendlyTitle,
  getCapitalizedHeader,
  getUSDAmount,
  ROUTE_TYPES,
} from "../../../utils/common";
import {
  getWidgetCalculatorFields,
  getWidgetEmploymentData,
  getWidgetPlacesList,
  getWidgetScholarships,
} from "../../../api/widget";
import WidgetSignUpOverlay from "../WidgetSignUpOverlay/WidgetSignUpOverlay";
import {
  initialListingStateDataListing,
  widgetDataTableHeaders,
} from "../../organisms/EmploymentDataHub/EmploymentDataHubContants";
import { MAP_CATEGORIES, US_COORDIANTES } from "../../../utils/constants";
import GoogleMap from "../../atoms/GoogleMap/GoogleMap";
import WidgetSignUpBar from "../../atoms/WidgetSignUpBar/WidgetSignUpBar";
import { Button } from "../../atoms/Button";
import MuiTable from "../../atoms/MuiTable/MuiTable";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import WidgetFadedBanner from "../../atoms/WidgetFadedBanner/WidgetFadedBanner";
import CalculatorItem from "../CalculatorItem/CalculatorItem";
import { isNull } from "../../../utils/validations";

const cx = createModuleStyleExtractor(styles);

const SELECTED_CATEGORY_ID = 34;

const WidgetEmployment = ({ schoolData, onSignupClick = () => {} }) => {
  const [tableState, setTableState] = useState(initialListingStateDataListing);
  const [redirectPath, setRedirectPath] = useState("");
  const [scholarships, setScholarships] = useState([]);
  const [costDetails, setCostDetails] = useState([]);
  const [places, setPlaces] = useState([]);
  const [employmentDataLoader, setEmploymentDataLoader] = useState(true);
  const [costCalcLoader, setCostCalcLoader] = useState(true);
  const [mapLoader, setMapLoader] = useState(true);
  const [showSignupOverlay, setShowSignupOverlay] = useState(false);
  const [overlayTitle, setOverlayTitle] = useState("");

  const onlyEmploymentData =
    tableState && isNull(costDetails) && isNull(scholarships) && isNull(places);

  const isEmploymentDataEnabled =
    schoolData?.school_module_permission?.["employment_data"] &&
    schoolData?.school_module_permission?.["opt_upload"];

  const onlyMap =
    places &&
    schoolData?.school_module_permission?.["campus_map"] &&
    schoolData?.school_module_permission?.["campus_map_data_uploaded"] &&
    (isNull(tableState) || !isEmploymentDataEnabled) &&
    ((isNull(costDetails) && isNull(scholarships)) ||
      !schoolData?.school_module_permission?.["cost_calculator"]);

  const onlyTutionCalculator =
    (scholarships || costDetails) &&
    (isNull(tableState) || !isEmploymentDataEnabled) &&
    (isNull(places) ||
      !schoolData?.school_module_permission?.["campus_map"] ||
      !schoolData?.school_module_permission?.["campus_map_data_uploaded"]);

  const getPathTitle = (pathName = "university insights") =>
    `Sign up to access ${pathName}`;
  const handleSignupClick = (type) => {
    let pathTitle = "";
    let routePath = "";
    switch (type) {
      case "scholarships":
        pathTitle = getPathTitle("scholarships & financial aid");
        routePath = ROUTE_TYPES.CALCULATOR;
        break;
      case "calculator":
        pathTitle = getPathTitle("cost of attendance");
        routePath = ROUTE_TYPES.CALCULATOR;
        break;
      case "map":
        pathTitle = getPathTitle("campus map");
        routePath = ROUTE_TYPES.CAMPUS_MAP;
        break;
      case "employment":
        pathTitle = getPathTitle("employment data");
        routePath = ROUTE_TYPES.EMPLOYMENT_DATA;
        break;
      default:
        pathTitle = getPathTitle();
    }
    setRedirectPath(routePath);
    setOverlayTitle(pathTitle);
    setShowSignupOverlay(true);
    onSignupClick();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    // Stops bruteforce api call on link hit if permission if false
    if (!isEmploymentDataEnabled) {
      setTableState(null);
      setEmploymentDataLoader(false);
    } else {
      (async () => {
        setEmploymentDataLoader(true);
        // Get employment data
        const response = await getWidgetEmploymentData(schoolData?.token);
        if (response?.success && response?.data?.opts?.length) {
          // Process listing data
          let _data = [];
          response.data.opts.forEach((record) => {
            _data.push([
              getCapitalizedHeader(record.company),
              getCapitalizedHeader(record.job_title),
              record.year,
              createFriendlyTitle(record.major),
              record.employment_type,
              createFriendlyTitle(record.nationality),
            ]);
          });
          setTableState((current) => ({
            ...current,
            loader: false,
            data: _data,
            page: parseInt(response?.data?.current_page),
            per_page: tableState?.per_page,
            total_pages: response?.data?.total_pages,
          }));
          setTimeout(() => setEmploymentDataLoader(false), [1000]);
          // setTimeout covers an edge case where the loader is skipped while DOM prepares in the UI for ref school:facelift both school
        } else {
          setEmploymentDataLoader(false);
        }
      })();
    }

    if (
      !schoolData?.school_module_permission?.["campus_map"] ||
      !schoolData?.school_module_permission?.["campus_map_data_uploaded"]
    ) {
      setTableState(null);
      setMapLoader(false);
    } else {
      (async () => {
        // Get places data
        const response = await getWidgetPlacesList(
          schoolData?.id,
          schoolData?.token,
          SELECTED_CATEGORY_ID
        );
        if (response.success && response?.data?.places?.length) {
          setPlaces({
            pois: response?.data?.places?.map((item) => ({
              key: item?.name,
              location: {
                lat: parseFloat(item?.latitude),
                lng: parseFloat(item?.longitude),
              },
              ...item,
              category: {
                ...(response?.data?.place_category ?? []),
              },
            })),
            category: MAP_CATEGORIES?.filter(
              (item) => item.id === SELECTED_CATEGORY_ID
            )[0],
          });
        } else {
          setPlaces(null);
        }
        setMapLoader(false);
      })();
    }

    if (!schoolData?.school_module_permission?.["cost_calculator"]) {
      setScholarships(null);
      setCostDetails(null);
      setCostCalcLoader(false);
    } else {
      (async () => {
        // Get scholarship data
        const response = await getWidgetScholarships(
          schoolData?.id,
          schoolData?.token
        );
        if (response?.success && response?.data) {
          const _scholarships = response?.data?.scholarships?.length
            ? [
                ...response?.data?.scholarships?.map((scholarship) => ({
                  id: scholarship?.id,
                  title: scholarship?.title,
                  amount: scholarship?.amount,
                  funding_type: scholarship?.funding_type,
                })),
              ]
            : null;
          setScholarships(_scholarships);
        } else {
          setScholarships(null);
        }
      })();

      (async () => {
        // Get calculator data
        const response = await getWidgetCalculatorFields(
          schoolData?.id,
          schoolData?.token
        );
        if (response?.success && response?.data) {
          const _costDetails = response?.data?.cost_calculators?.length
            ? [
                ...response?.data?.cost_calculators?.reduce(
                  (details, cost_calculator) => {
                    if (cost_calculator?.amount) {
                      details.push({
                        id: cost_calculator?.id,
                        name: cost_calculator?.name,
                        amount: cost_calculator?.amount,
                      });
                    }
                    return details;
                  },
                  []
                ),
              ]
            : null;
          setCostDetails(_costDetails?.length > 0 ? _costDetails : null);
        } else {
          setCostDetails(null);
        }
        setCostCalcLoader(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolData?.id, schoolData?.school_module_permission]);

  return (
    <div className={cx("m-widget-employment")}>
      {showSignupOverlay && (
        <WidgetSignUpOverlay
          token={schoolData?.token}
          title={overlayTitle ?? getPathTitle()}
          closeOverlay={() => setShowSignupOverlay(false)}
          route_type={redirectPath}
        />
      )}
      <WidgetSignUpBar
        text="Get full access on Interstride's portal"
        buttonText="Sign up for free"
        onSignupClick={() => handleSignupClick()}
      />
      <div className={cx("m-widget-employment__wrapper")}>
        {tableState !== null && isEmploymentDataEnabled ? (
          <div
            id={onlyEmploymentData ? "widget-expanded-section" : ""}
            className={cx("m-widget-employment__wrapper__employment-table")}
          >
            <h2>{`${schoolData?.name ?? "University"}'s Employment Data`}</h2>
            {employmentDataLoader ? (
              <div
                className={cx(
                  "m-widget-employment__wrapper__employment-table__loader"
                )}
              >
                <ShimmerThumbnail rounded />
              </div>
            ) : (
              <div
                className={cx(
                  "m-widget-employment__wrapper__employment-table__wrapper"
                )}
              >
                <h3
                  className={cx(
                    "m-widget-employment__wrapper__employment-table__wrapper__title"
                  )}
                >
                  All employment data
                </h3>
                <div
                  className={cx(
                    "m-widget-employment__wrapper__employment-table__wrapper__filters"
                  )}
                >
                  <div
                    className={cx(
                      "m-widget-employment__wrapper__employment-table__wrapper__filters__title"
                    )}
                  >
                    Filters
                  </div>
                  <div
                    className={cx(
                      "m-widget-employment__wrapper__employment-table__wrapper__filters__item"
                    )}
                    onClick={() => handleSignupClick("employment")}
                  >
                    <MuiSelect
                      placeholder="Major"
                      justifyContent
                      options={[]}
                      isDisabled={true}
                    />
                  </div>
                  <div
                    className={cx(
                      "m-widget-employment__wrapper__employment-table__wrapper__filters__item"
                    )}
                    onClick={() => handleSignupClick("employment")}
                  >
                    <MuiSelect
                      placeholder="Nationality"
                      justifyContent
                      options={[]}
                      isDisabled={true}
                    />
                  </div>
                  <div
                    className={cx(
                      "m-widget-employment__wrapper__employment-table__wrapper__filters__item"
                    )}
                    onClick={() => handleSignupClick("employment")}
                  >
                    <MuiSelect
                      placeholder="Job Location"
                      justifyContent
                      options={[]}
                      isDisabled={true}
                    />
                  </div>
                </div>
                <div
                  className={cx(
                    "m-widget-employment__wrapper__employment-table__wrapper__mui-table"
                  )}
                >
                  <MuiTable
                    tableHeaders={widgetDataTableHeaders}
                    tableData={tableState.data}
                    pagination={false}
                    page={tableState.page}
                    total_pages={tableState?.total_pages}
                    tableLoader={tableState?.loader}
                    noDataTextHeader="Looks like there are no career data with this search."
                    noDataTextBody="Try removing some filters to expand your search and get more results."
                    changeSortingFn={() => handleSignupClick("employment")}
                  />
                </div>
                <WidgetFadedBanner
                  actionText="Unlock full international employment data"
                  onClick={() => handleSignupClick("employment")}
                />
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
        <div
          className={cx([
            "m-widget-employment__wrapper__right-sidebar",
            !tableState || !isEmploymentDataEnabled
              ? "m-widget-employment__wrapper__right-sidebar--no-emp-data"
              : "",
          ])}
        >
          {schoolData?.school_module_permission?.["cost_calculator"] &&
          (scholarships?.length > 0 || costDetails?.length > 0) ? (
            <div id={onlyTutionCalculator ? "widget-expanded-section" : ""}>
              <h2>Tuition calculator</h2>
              {costCalcLoader ? (
                <>
                  <div
                    className={cx(
                      "m-widget-employment__wrapper__right-sidebar__card--shimmer"
                    )}
                    style={{ padding: 0 }}
                  >
                    <ShimmerThumbnail rounded height={150} />
                  </div>
                  <div
                    className={cx(
                      "m-widget-employment__wrapper__right-sidebar__card--shimmer"
                    )}
                    style={{ padding: 0 }}
                  >
                    <ShimmerThumbnail rounded height={150} />
                  </div>
                </>
              ) : (
                <>
                  {scholarships !== null && scholarships?.length > 0 ? (
                    <div
                      className={cx(
                        "m-widget-employment__wrapper__right-sidebar__card"
                      )}
                    >
                      <h3>Scholarships & financial aid</h3>
                      <div
                        className={cx(
                          "m-widget-employment__wrapper__right-sidebar__card__scholarships"
                        )}
                      >
                        {scholarships?.map((scholarship, idx) => (
                          <div
                            className={cx(
                              "m-widget-employment__wrapper__right-sidebar__card__scholarships__entry"
                            )}
                            key={`card_entry_${idx}`}
                          >
                            <div
                              className={cx(
                                "m-widget-employment__wrapper__right-sidebar__card__scholarships__entry__header"
                              )}
                            >
                              <div
                                className={cx(
                                  "m-widget-employment__wrapper__right-sidebar__card__scholarships__entry__header__title"
                                )}
                              >
                                {scholarship?.title}{" "}
                              </div>
                              <span
                                className={cx([
                                  "m-widget-employment__wrapper__right-sidebar__card__scholarships__entry__header__tag",
                                  `m-widget-employment__wrapper__right-sidebar__card__scholarships__entry__header__tag--${
                                    scholarship?.funding_type === "scholarship"
                                      ? "yellow"
                                      : "green"
                                  }`,
                                ])}
                              >
                                {getCapitalizedHeader(
                                  scholarship?.funding_type?.replace(
                                    "_",
                                    " "
                                  ) ?? ""
                                )}
                              </span>
                            </div>
                            {scholarship?.amount ? (
                              <div
                                className={cx(
                                  "m-widget-employment__wrapper__right-sidebar__card__scholarships__entry__header__value"
                                )}
                              >
                                <span>Amount</span>
                                {getUSDAmount(Number(scholarship?.amount))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))}
                      </div>
                      <Button onClick={() => handleSignupClick("scholarships")}>
                        View more
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {costDetails !== null ? (
                    <div
                      className={cx(
                        "m-widget-employment__wrapper__right-sidebar__card"
                      )}
                    >
                      <h3>Cost of attendance</h3>
                      {!tableState ? (
                        <div
                          className={cx(
                            "m-widget-employment__wrapper__right-sidebar__card__sample-cost"
                          )}
                        >
                          {costDetails?.slice(0, 3)?.map((item, idx) => (
                            <CalculatorItem
                              item={{
                                ...item,
                                adjustment_type: "fixed",
                                amount: item?.amount ?? "0",
                              }}
                              key={`calculator_item_${idx}`}
                            />
                          ))}
                          <h4>
                            Note: Values displayed here are for reference only
                          </h4>
                        </div>
                      ) : (
                        <table>
                          <tbody>
                            {costDetails.slice(0, 3)?.map((costDetail) => (
                              <tr>
                                <td>{costDetail?.name}</td>
                                <td>
                                  {getUSDAmount(Number(costDetail?.amount))}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                      <Button onClick={() => handleSignupClick("calculator")}>
                        View all & customize
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          ) : (
            <></>
          )}
          {places !== null &&
          schoolData?.school_module_permission?.["campus_map"] &&
          schoolData?.school_module_permission?.["campus_map_data_uploaded"] ? (
            <div id={onlyMap ? "widget-expanded-section" : ""}>
              <h2>Campus Map</h2>
              {mapLoader ? (
                <div
                  className={cx(
                    "m-widget-employment__wrapper__right-sidebar__map"
                  )}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <ShimmerThumbnail rounded height={onlyMap ? 500 : 225} />
                  </div>
                </div>
              ) : (
                <div
                  className={cx([
                    "m-widget-employment__wrapper__right-sidebar__map",
                    onlyMap
                      ? "m-widget-employment__wrapper__right-sidebar__map--only-map"
                      : "",
                  ])}
                >
                  <GoogleMap
                    data={places}
                    defaultCenter={US_COORDIANTES}
                    defaultZoom={4}
                    showMarkersInfoView={false}
                  />
                  <div
                    className={cx(
                      "m-widget-employment__wrapper__right-sidebar__map__overlay"
                    )}
                  >
                    <Button onClick={() => handleSignupClick("map")}>
                      Unlock campus map
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default memo(WidgetEmployment);
