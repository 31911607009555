import { createSlice, current } from "@reduxjs/toolkit";

import { getConversationByConversationID } from "./selectors";
import { initialState } from "./state";
import { extractMessage } from "../../../utils/common";
import toastify from "../../../utils/toast";

export const chatReducer = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetChatState: () => initialState,

    setChatsList: (state, action) => {
      const { payload } = action || {};
      return {
        ...state,
        chats: [...payload.chats],
      };
    },
    setIsChatsLoaded: (state, action) => {
      const { payload } = action || {};
      return {
        ...state,
        isChatsLoaded: payload.isChatsLoaded,
      };
    },
    setIsChatsLoading: (state, action) => {
      return {
        ...state,
        isChatsLoading: action.payload.isChatsLoading,
      };
    },

    setCurrentConversationId: (state, action) => {
      // Handle case where user clicks already active conversation
      if (state.activeConversation === action.payload) return;

      // Add the current conversation to local storage if not already present
      const persistedChatConversationID = parseInt(
        localStorage.getItem("INTERSTRIDE_ACTIVE_CHAT_ID") || -1
      );
      if (persistedChatConversationID !== action.payload)
        localStorage.setItem(
          "INTERSTRIDE_ACTIVE_CHAT_ID",
          action.payload || -1
        );
      return {
        ...state,
        previousActiveConversation: state.activeConversation,
        activeConversation: action.payload,
      };
    },

    setConversationInformation: (state, action) => {
      const { conversation_id, conversation, replace = false } = action.payload;
      try {
        const _conversation = getConversationByConversationID(
          state,
          conversation_id
        );
        if (!_conversation || _conversation?.current_page === 1 || replace) {
          return {
            ...state,
            conversations: {
              ...state.conversations,
              [conversation_id]: {
                ...conversation,
              },
            },
          };
        }
      } catch (error) {}
    },

    setReloadChats: (state, action) => {
      return {
        ...state,
        reloadChats: action.payload,
      };
    },

    updateChatsUnreadCount: (state, action) => {
      const { conversation_id, count = 0 } = action.payload;
      return {
        ...state,
        chats: state.chats?.map((chat) =>
          chat?.conversation_id === conversation_id
            ? { ...chat, count }
            : { ...chat }
        ),
      };
    },
    updateConversationInformation: (state, action) => {
      const { conversation_id, messages, pagination } = action.payload;
      const conversation = getConversationByConversationID(
        state,
        conversation_id
      );
      const updatedConversation = {
        ...conversation,
        messages: [...messages, ...conversation.messages],
        ...pagination,
      };
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [conversation_id]: {
            ...updatedConversation,
          },
        },
      };
    },
    resetPreviousConversationMessages: (state) => {
      if (
        state.conversations[state?.previousActiveConversation]?.messages
          ?.length > 0 &&
        !state?.freshChat?.includes(state?.previousActiveConversation)
      ) {
        state.conversations[state?.previousActiveConversation].messages = [];
      }
    },
    setCurrentSearchMessageItem: (state, action) => {
      return {
        ...state,
        currentSearchMessageItem: action.payload,
      };
    },
    setTriggerReloadUnreadCountUpdate: (state, action) => {
      return {
        ...state,
        triggerReloadUnreadCount: action.payload,
      };
    },

    setIsProfileViewOnly: (state, action) => {
      return {
        ...state,
        isProfileView: action.payload,
      };
    },
    setIsConversationViewOnly: (state, action) => {
      return {
        ...state,
        isConversationView: action.payload,
      };
    },
    setIsChatPreviewOnly: (state, action) => {
      return {
        ...state,
        isChatPreview: action.payload,
      };
    },
    setLinkPreview: (state, action) => {
      return {
        ...state,
        linkPreviews: {
          ...state.linkPreviews,
          [action.payload.key]: action.payload.data,
        },
      };
    },
    bulkUpdate: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },

    setChatContactsList: (state, action) => {
      return {
        ...state,
        contacts: [...action.payload],
      };
    },
    setNetworkFilters: (state, action) => {
      return {
        ...state,
        networkFilters: {
          ...action.payload,
        },
      };
    },

    createFreshChatRecord: (state, action) => {
      return {
        ...state,
        freshChat: [...state.freshChat, action.payload.id],
      };
    },
    flushFreshChats: (state, action) => {
      return {
        ...state,
        freshChat: [],
      };
    },

    setCurrentNetworkFilter: (state, action) => {
      return {
        ...state,
        currentNetworkFilters: action.payload?.clear
          ? {}
          : {
              ...action.payload,
            },
      };
    },
    removeUserFromFreshChat: (state, action) => {
      const { id } = action.payload;
      //remove from fresh chat array
      return {
        ...state,
        freshChat: state.freshChat.filter((item) => item !== id),
      };
    },
    removeConversationByConversationID: (state, action) => {
      const { conversation_id } = action.payload;
      // Check if last conversation, then reset active conversation
      if (Object.keys(state.conversations).length === 1) {
        state.activeConversation = -1;
      }
      const conversations = state.conversations;
      delete conversations[conversation_id];
      // // remove chats
      // if (state.chats && state.chats.includes(chat => chat.conversation_id === conversation_id)){
      //   state.chats = state.chats.filter(chat => chat.id !== conversation_id)
      // }
    },
    toggleMuteStatus: (state, action) => {
      const { conversationId } = action.payload;
      const _chats = current(state.chats);
      if (!_chats)
        return {
          ...state,
        };
      const chats = _chats.map((chat) =>
        chat.conversation_id === conversationId
          ? { ...chat, mute_chat: !chat.mute_chat }
          : { ...chat }
      );

      return {
        ...state,
        chats: [...chats],
      };
    },

    updateGroupDetails: (state, action) => {
      const { conversationId, group_details } = action.payload;

      const _conversation = current(state.conversations[conversationId]);

      if (_conversation) {
        return {
          ...state,
          conversations: {
            ...state.conversations,
            [conversationId]: {
              ..._conversation,
              group_details: {
                ...group_details,
              },
            },
          },
        };
      }
      return {
        ...state,
      };
    },

    setIsGroupCreating: (state, action) => {
      const { status } = action.payload;
      return {
        ...state,
        isGroupCreating: status ?? !state.isGroupCreating,
      };
    },

    pushNewMessage: (state, action) => {
      const { conversationId, message } = action.payload;

      const conversation = current(state.conversations[conversationId]);
      if (!conversation) {
        return {
          ...state,
        };
      }
      const { messages } = conversation || {};
      if (messages?.length > 0) {
        //noticed that webhook sometimes return duplicate message
        //Todo: Verify in future
        const messageAlreadyExists = messages.find(
          (item) => item.id === message.id
        );
        if (messageAlreadyExists) {
          return {
            ...state,
          };
        }
      }

      // setTimeout(() => {
      //   callback && callback();
      // }, [0]);

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [conversationId]: {
            ...conversation,
            messages: [...conversation.messages, message],
          },
        },
      };
    },

    /**
     * Update the latest message inside the chats preview
     * @param {*} latest_message
     * @param {*} conversation_id
     * @returns
     */
    updateLatestMessage: (state, action) => {
      const { latest_message, conversation_id } = action.payload;
      try {
        const _chats = current(state.chats);
        if (_chats?.length < 1) return;
        const chats = _chats.map((chat) =>
          chat.conversation_id === conversation_id
            ? { ...chat, latest_message, time: new Date().toISOString() }
            : { ...chat }
        );

        if (_chats[0].conversation_id !== conversation_id) {
          const chat = chats.find(
            (chat) => chat.conversation_id === conversation_id
          );
          const items = chats.filter(
            (chat) => chat.conversation_id !== conversation_id
          );

          return {
            ...state,
            chats: [chat, ...items],
          };
        }
        return {
          ...state,
          chats: [...chats],
        };
      } catch (error) {
        console.log(`error ${error.message}`);
      }
    },
    setIsMessageSending: (state, action) => {
      return {
        ...state,
        messageSending: action.payload,
      };
    },
    setIsAttachment: (state, action) => {
      return {
        ...state,
        attachment: action.payload,
      };
    },
    setIsFullChatView: (state, _) => {
      return {
        ...state,
        isFullChatMode: !state.isFullChatMode,
      };
    },

    setIsDiscoverNetworkFilterInProgress: (state, action) => {
      return {
        ...state,
        isDiscoverNetworkFilterInProgress: action.payload,
      };
    },
    // Processes conversationChannel websocket broadcast message
    webSocketActionRecevied: (state, action) => {
      const broadcastMessage = action.payload.broadcastData;

      if (broadcastMessage?.error_code || broadcastMessage?.error_message) {
        broadcastMessage.error_message &&
          toastify(broadcastMessage.error_message, "error");
        state.messageSending = false;
        state.attachment = false;
        state.isWaitingSocketMessage = false;
        state.broadcastData = broadcastMessage;
        state.reloadChats = true;
        return;
      }

      // Check if chat is assigned
      if (broadcastMessage.action === "assign_chat") {
        // Reload chats to call list_messages api again
        state.reloadChats = true;
        return;
      }

      // Check if personal chat converted to group chat over socket (admin monitor chat use case)
      if (
        broadcastMessage.type === "Group Chat" &&
        broadcastMessage.action === "group_auto_created"
      ) {
        // Update chat object
        const chatIndex = state.chats.findIndex(
          (chat) =>
            chat.conversation_window_id ===
            broadcastMessage.conversation_window_id
        );
        const _chat = {
          latest_message: "",
          conversation_id: broadcastMessage.conversation_id,
          conversation_window_id: broadcastMessage.conversation_window_id,
          title: broadcastMessage.group_title,
          time: "",
          count: 0,
          user_image: broadcastMessage.group_icon,
          receiver_id: "",
          user_deleted: false,
          mute_chat: false,
          group_user_details: broadcastMessage.group_user_details,
          total_group_members: broadcastMessage.group_members_count,
          group_admins: broadcastMessage.group_admins,
          last_message_sender_name: "",
        };
        if (chatIndex === -1) {
          state.chats.push(_chat);
        } else {
          state.chats[chatIndex] = _chat;
        }
        // update conversation Object
        state.conversations[broadcastMessage.conversation_id] = {
          chat: {
            conversation_id: broadcastMessage.conversation_id,
            conversation_window_title: broadcastMessage.group_title,
            conversation_window_id: broadcastMessage.conversation_window_id,
            mute_chat: false,
          },
          group_details: {
            total_participants: broadcastMessage.group_members_count,
            conversation_window_id: broadcastMessage.conversation_window_id,
            group_title: broadcastMessage.group_title,
            group_icon: broadcastMessage.group_icon,
            group_user_details: broadcastMessage.group_user_details,
            group_info: broadcastMessage.group_info,
          },
          user_info: null,
          messages: [],
          current_page: null,
          total_messsages: 0,
          total_pages: 0,
          next_page: null,
        };

        // Reload chats to call list_messages api again
        state.reloadChats = true;

        return;
      }

      // Check if group is created over socket and update state
      if (
        broadcastMessage.type === "Group Chat" &&
        broadcastMessage.group_created
      ) {
        state.chats.push({
          latest_message: "",
          conversation_id: broadcastMessage.conversation_id,
          conversation_window_id: broadcastMessage.conversation_window_id,
          title: broadcastMessage.group_title,
          time: "",
          count: 0,
          user_image: broadcastMessage.group_icon,
          receiver_id: "",
          user_deleted: false,
          mute_chat: false,
          group_user_details: broadcastMessage.group_user_details,
          total_group_members: broadcastMessage.group_members_count,
          group_admins: broadcastMessage.group_admins,
          last_message_sender_name: "",
        });
        state.conversations[broadcastMessage.conversation_id] = {
          chat: {
            conversation_id: broadcastMessage.conversation_id,
            conversation_window_title: broadcastMessage.group_title,
            conversation_window_id: broadcastMessage.conversation_window_id,
            mute_chat: false,
          },
          group_details: {
            total_participants: broadcastMessage.group_members_count,
            conversation_window_id: broadcastMessage.conversation_window_id,
            group_title: broadcastMessage.group_title,
            group_icon: broadcastMessage.group_icon,
            group_user_details: broadcastMessage.group_user_details,
            group_info: broadcastMessage.group_info,
          },
          user_info: null,
          messages: [],
          current_page: null,
          total_messsages: 0,
          total_pages: 0,
          next_page: null,
        };

        // Check if no chat is selected (case where there are no chats initially), then select the newly created group chat
        if (state.activeConversation === -1) {
          state.activeConversation = broadcastMessage.conversation_id;
        }
        return;
      }

      const message = extractMessage(broadcastMessage);
      if (broadcastMessage.sender_id === broadcastMessage.receiver_id) {
        // if message sent
        const chatIndex = state.chats.findIndex(
          (chat) => chat.conversation_id === state.activeConversation
        );

        // Check if fresh chat then update draft conversation id with socket receiver conversation id
        if (state.freshChat.includes(state.activeConversation)) {
          state.conversations[broadcastMessage.receiver_conversation_id] =
            state.conversations[state.activeConversation];
          delete state.conversations[state.activeConversation];
          state.conversations[
            broadcastMessage.receiver_conversation_id
          ].chat.conversation_id = broadcastMessage.receiver_conversation_id;
          state.conversations[
            broadcastMessage.receiver_conversation_id
          ].chat.conversation_window_id =
            broadcastMessage.conversation_window_id;
          state.freshChat = state.freshChat.filter(
            (item) => item !== state.activeConversation
          );
          if (state?.chats?.[chatIndex]) {
            state.chats[chatIndex].conversation_id =
              broadcastMessage.receiver_conversation_id;
            state.chats[chatIndex].conversation_window_id =
              broadcastMessage.conversation_window_id;
          }
          state.activeConversation = broadcastMessage.receiver_conversation_id;
        }

        if (state?.chats?.[chatIndex]) {
          state.chats[chatIndex].conversation_window_id =
            broadcastMessage.conversation_window_id;
          state.chats[chatIndex].latest_message = broadcastMessage.message;
          state.chats[chatIndex].time = broadcastMessage.send_at;
          state.chats[chatIndex].file_name = broadcastMessage.file_name;
          state.chats[chatIndex].conversation_type = "simple";
          state.chats[chatIndex].last_message_sender_name =
            broadcastMessage?.sender_name;
        }
        if (state?.conversations?.[state.activeConversation])
          state.conversations[state.activeConversation].messages.push(message);

        state.messageSending = false;
      } else {
        // If Message received
        let chatIndex = -1;

        // Find chat index in redux store
        chatIndex = state.chats.findIndex(
          (chat) =>
            chat?.conversation_window_id &&
            broadcastMessage?.conversation_window_id &&
            chat.conversation_window_id ===
              broadcastMessage.conversation_window_id
        );

        if (chatIndex !== -1) {
          // Chat previously initiated condition
          // Update chat
          state.chats[chatIndex].conversation_window_id =
            broadcastMessage.conversation_window_id;
          state.chats[chatIndex].latest_message = broadcastMessage.message;
          state.chats[chatIndex].time = broadcastMessage.send_at;
          state.chats[chatIndex].file_name = broadcastMessage.file_name;
          state.chats[chatIndex].last_message_sender_name =
            broadcastMessage?.sender_name;
          state.chats[chatIndex].conversation_type =
            broadcastMessage?.request_meeting ? "request_meeting" : "simple";
          // Update conversation
          const conversationId = state.chats[chatIndex].conversation_id;
          state.conversations[conversationId].messages.push(message);
          state.conversations[conversationId].chat.conversation_window_id =
            broadcastMessage.conversation_window_id;
        } else {
          // Chat not perviously initiated condition
          // Update State
          state.chats.push({
            last_message_sender_name: "",
            latest_message: broadcastMessage.message,
            conversation_id: broadcastMessage.receiver_conversation_id,
            conversation_window_id: broadcastMessage.conversation_window_id,
            title: broadcastMessage.sender_name,
            time: broadcastMessage.send_at,
            count: broadcastMessage.unread_messsages,
            user_image: broadcastMessage.sender_image,
            receiver_id: broadcastMessage.sender_id,
            user_deleted: false,
            mute_chat: false,
            group_user_details: [],
            total_group_members: 0,
            group_admins: "",
            file_name: broadcastMessage.file_name,
            conversation_type: broadcastMessage?.request_meeting
              ? "request_meeting"
              : "simple",
          });
          // Update state
          state.conversations[broadcastMessage.receiver_conversation_id] = {
            chat: {
              conversation_id: broadcastMessage.receiver_conversation_id,
              conversation_window_id: broadcastMessage.conversation_window_id,
              conversation_window_title: broadcastMessage.sender_name,
              mute_chat: false,
            },
            group_details: {},
            messages: [message],
            current_page: 1,
            total_messsages: 1,
            total_pages: 1,
            next_page: null,
          };

          // Check if no chat is selected (case where there are no chats initially), then select the newly created group chat
          if (state.activeConversation === -1) {
            state.activeConversation =
              broadcastMessage.receiver_conversation_id;
          }
        }
      }
    },
    setPreviousActiveConversation: (state, action) => {
      return {
        ...state,
        previousActiveConversation: action.payload,
      };
    },
    setSocketReactionsConnectionStatus: (state, action) => {
      return {
        ...state,
        socketReactionsConnectionStatus: action.payload,
      };
    },
    // Processes reactions channel websocket broadcast message
    processWebsocketReactionsMessage: (state, action) => {
      // Data destructure
      const {
        count,
        emoji,
        emoji_unicode,
        message_id,
        reacted_users = [],
      } = action.payload || {};

      // Get active conversation and make copy to edit
      let _conversation = { ...state.conversations[state.activeConversation] };

      // Find index of message on which reaction is applied to
      const messageIndex = _conversation?.messages?.findIndex(
        (item) => item?.id === message_id
      );

      // If no message found, exit out of function (Safety check)
      if (messageIndex === -1) return state;

      // Find reactions data of the message
      let _reactions_data =
        _conversation?.messages[messageIndex]?.reactions?.reactions_data || [];

      // Find index of reaction belonging to the socket emoji
      const messageReactionsIndex = _reactions_data?.findIndex(
        (item) => item.emoji_unicode === emoji_unicode
      );

      if (messageReactionsIndex === -1) {
        _reactions_data.push({
          count,
          emoji,
          emoji_unicode,
          reacted_users,
        });
      } else if (messageReactionsIndex !== -1 && count === 0) {
        _reactions_data.splice(messageReactionsIndex, 1);
      } else if (messageReactionsIndex !== -1 && count > 0) {
        _reactions_data[messageReactionsIndex].count = count;
        _reactions_data[messageReactionsIndex].reacted_users = reacted_users;
      }

      // Use case handle. BE gives empty reactions object in case none is present.
      // Hence we have to manually create a placeholder, in order to insert values
      if (_conversation?.messages[messageIndex]?.reactions === undefined) {
        _conversation.messages[messageIndex].reactions = {};
      }

      // Update placeholder conversation object
      _conversation.messages[messageIndex].reactions.reactions_data =
        _reactions_data;

      // Update state
      state.conversations[state.activeConversation] = { ..._conversation };
    },
  },
});

export const {
  resetChatState,
  setChatsList,
  setIsChatsLoaded,
  setIsChatsLoading,
  bulkUpdate,
  setLinkPreview,
  setConversationInformation,
  setCurrentConversationId,
  setReloadChats,
  updateChatsUnreadCount,
  updateConversationInformation,
  setCurrentSearchMessageItem,
  setTriggerReloadUnreadCountUpdate,
  setIsProfileViewOnly,
  setIsChatPreviewOnly,
  setIsConversationViewOnly,
  setChatContactsList,
  setNetworkFilters,
  createFreshChatRecord,
  flushFreshChats,
  setCurrentNetworkFilter,
  removeUserFromFreshChat,
  removeConversationByConversationID,
  toggleMuteStatus,
  updateGroupDetails,
  setIsGroupCreating,
  pushNewMessage,
  updateLatestMessage,
  setIsMessageSending,
  setIsAttachment,
  setIsFullChatView,
  setIsDiscoverNetworkFilterInProgress,
  webSocketActionRecevied,
  setPreviousActiveConversation,
  resetPreviousConversationMessages,
  setSocketReactionsConnectionStatus,
  processWebsocketReactionsMessage,
} = chatReducer.actions;

export default chatReducer.reducer;
