export const defaultState = {
  message: "",
  attachment: "",
  emojiContainer: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "state.reset":
      return {
        ...state,
        message: "",
        attachment: "",
      };
    case "state.mutate":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "state.appendEmoji":
      return {
        ...state,
        message: state.message + action.value,
        emojiContainer: false,
      };

    case "bulkUpdate":
      return {
        ...state,
        ...action.value,
      };
    default:
      return {
        ...state,
      };
  }
};
