import React, { useEffect, useCallback, useState } from "react";
import {
  useRoutes,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bugsnag from "@bugsnag/js";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import TagManager from "react-gtm-module";
import { isEmpty } from "lodash";

import "./App.css";
import { ROUTES, UNPROTECTED_ROUTES } from "./routes";
import MainLayout from "./layout/MainLayout/MainLayout";
import Dashboard from "./components/organisms/Dashboard/Dashboard";
import Login from "./components/organisms/Login/Login";
import Chat from "./components/organisms/Chat/Chat";
import Profile from "./components/organisms/Profile/Profile";
import WebinarsV2 from "./components/organisms/Webinars/V2/WebinarsV2";
import Resources from "./components/organisms/Resources/Resources";
import StudentServices from "./components/organisms/StudentServices/StudentServices";
import EmploymentDataHub from "./components/organisms/EmploymentDataHub/EmploymentDataHub";
import Checklists from "./components/organisms/Checklists/Checklists";
import Notifications from "./components/organisms/Notifications/Notifications";
import NotificationsSettings from "./components/organisms/NotificationsSettings/NotificationsSettings";
import CampusMap from "./components/organisms/CampusMap/CampusMap";
import Calculator from "./components/organisms/Calculator/Calculator";
import {
  addMicrosoftClarity,
  applyTheme,
  getTokenFromURL,
  setBodyBackground,
  setMSClarityTagsAfterAddingToken,
  fetchUTMParamsFromURL,
  getRedirectRouteFromURL,
  fetchRouteParamsFromURL,
  isPageAccessible,
  setTitleForPath,
} from "./utils/common";
import { DEFAULT_PRIMARY_COLOR, GOOGLE_TAG_MANAGER_ID } from "./config/config";
import {
  validateUniversityToken,
  validateEmailToken,
  addSchoolForProspect,
  updateLastActiveSessionAPI,
  getUserPermissions,
} from "./api/onboarding";
import {
  setThemeColor,
  setLastPerDayAppLoad,
  setSavedRoute,
  wipeOutSavedRoute,
} from "./redux/reducers/preservedReducer";
import {
  setLoginData,
  setLastActiveSession,
  updateUserPermissions,
} from "./redux/reducers/userReducer";
import AmbassadorSignUp from "./components/organisms/AmbassadorSignUp/AmbassadorSignUp";
import toastify from "./utils/toast";
import DiscoverNetwork from "./components/organisms/DiscoverNetwork/DiscoverNetwork";
import { useChatsList } from "./hooks/useChatsList";
import Topics from "./components/organisms/Topics/Topics";
import ConfirmEmail from "./components/organisms/ConfirmEmail/ConfirmEmail";
import moment from "moment";
import { isNull } from "./utils/validations";
import AmbassadorAcceptInvite from "./components/organisms/AmbassadorAcceptInvite/AmbassadorAcceptInvite";
import { setReloadChats } from "./redux/reducers/chat/reducers";
import useFirebase from "./hooks/useFirebase";
import ForgotPassword from "./components/organisms/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/organisms/ResetPassword/ResetPassword";
import { Mixpanel } from "./MixPanel/mixpanel";
import HelpCenter from "./components/organisms/HelpCenter/HelpCenter";
import PrivacyPolicy from "./components/organisms/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/organisms/TermsAndConditions/TermsAndConditions";
import ProspectParentInviteSignUp from "./components/organisms/ProspectParentInviteSignUp/ProspectParentInviteSignUp";
import UniversityWidget from "./components/organisms/UniversityWidget/UniversityWidget";
import UniversityWidgetChatPopup from "./components/organisms/UniversityWidgetChatPopup/UniversityWidgetChatPopup";
import UniversityWidgetChat from "./components/organisms/UniversityWidgetChat/UniversityWidgetChat";
import NotificationUnsubscribe from "./components/organisms/NotificationUnsubscribe/NotificationUnsubscribe";
import ParentEffect from "./components/atoms/ParentEffect/ParentEffect";
import InterstridePageLoader from "./components/atoms/InterstridePageLoader/InterstridePageLoader";
import Courses from "./components/organisms/Courses/Courses";
import Community from "./components/organisms/Community/Community";

const LIST_MESSAGE_API_POOL_DELAY = 1800000; // time in ms 30 min for production, 1 min for staging

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { reloadChats } = useSelector((store) => store.chatStore);
  const { savedRoute = {} } = useSelector((store) => store.preservedState);

  useFirebase();
  useChatsList(); // Custom hook to manage chat list message api call upon different scenarios

  const [searchParams] = useSearchParams();
  const token =
    searchParams.get("token") ?? getTokenFromURL(location.pathname) ?? false;
  const prospect_token = searchParams.getAll("prospect_token")
    ? searchParams.get("prospect_token")
    : false;
  const invite_token = searchParams.getAll("invite_token")
    ? searchParams.get("invite_token")
    : false;

  const email = searchParams.getAll("email")
    ? decodeURIComponent(searchParams.get("email"))
    : false;

  const {
    isLogin,
    permissions,
    selectedSchool,
    keepSignedIn,
    tokenExpiry,
    lastActiveSession,
    isProspect,
    isParent,
  } = useSelector((state) => state.userState);
  const preservedState = useSelector((state) => state.preservedState);

  const [showPageLoader, setShowPageLoader] = useState(false);

  const isWidgetUser = isProspect || isParent;

  const initialEffect = useCallback(() => {
    // Get and save route params if present to be used after signup/login
    (() => {
      console.log("App load");
      console.log(window.location.href);
      const routeParams = fetchRouteParamsFromURL();
      if (routeParams && !isEmpty(routeParams)) {
        console.log("Saving route params -> " + JSON.stringify(routeParams));
        dispatch(setSavedRoute(routeParams));
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // As we need no backgroud on load of chat widget
    if (
      !location.pathname.includes(ROUTES.UNIVERSITY_WIDGET_CHAT_POPUP) &&
      !location.pathname.includes(ROUTES.UNIVERSITY_WIDGET)
    )
      setBodyBackground();

    const tagManagerArgs = {
      gtmId: GOOGLE_TAG_MANAGER_ID,
    };
    TagManager.initialize(tagManagerArgs);
    // eslint-disable-next-line
  }, []);

  // Fetch user's school permissions
  const fetchUserPermissions = async () => {
    if (selectedSchool?.id) {
      setShowPageLoader(true);
      console.log("Fetching permissions");
      const response = await getUserPermissions(selectedSchool?.id);
      if (response.success && response.data) {
        dispatch(updateUserPermissions(response.data));
      } else {
        navigate(getRedirectRouteFromURL(isWidgetUser), { replace: true });
        setShowPageLoader(false);
      }
    } else {
      navigate(getRedirectRouteFromURL(isWidgetUser), { replace: true });
    }
  };

  useEffect(() => {
    // Integration of Microsoft Clarity - ADMISSION-347
    if (process.env.REACT_APP_ENV !== "stage") addMicrosoftClarity();

    const checkUniversityToken = async () => {
      const tokenResponse = await validateUniversityToken(token);

      if (tokenResponse.success && tokenResponse.data) {
        const response = await addSchoolForProspect({
          token,
          // Pass UTM param to track source for logged-in user visit with token
          utm_params: {
            ...fetchUTMParamsFromURL(),
          },
        });

        if (response.success) {
          const { users = {}, schools = [] } = response.data;

          const latestSchoolId = schools?.[0]?.id;

          Mixpanel.track(`Visited with school token`, {
            token,
            school_id: latestSchoolId,
          });

          sessionStorage.setItem("isLogin", "1");
          Bugsnag.setUser(users.id, users.email, users.name);
          Mixpanel.identify(users.id);
          setMSClarityTagsAfterAddingToken(users, latestSchoolId);

          dispatch(
            setLoginData({
              user: { ...users },
              schools,
            })
          );
        } else {
          toastify(response.message);
        }
      } else {
        toastify(tokenResponse.message);
      }
    };

    const checkEmailToken = async () => {
      const response = await validateEmailToken(email, prospect_token);
      if (response.success) {
        toastify(response.message);
        navigate(ROUTES.LOGIN, { replace: true });
      } else {
        toastify(response.message);
        navigate(ROUTES.LOGIN, { replace: true });
      }
    };

    // Check for login & keep me signed in flow
    const isLogin = sessionStorage.getItem("isLogin");

    console.log("path ->", location.pathname);
    console.log("token ->", token);

    // Prioritize query string paths first
    if (invite_token) {
      // Skip redirection if invite token is present
    } else if (prospect_token && email) {
      console.log("Checking email token");
      checkEmailToken();
    } else if (location.pathname.includes(ROUTES.UNIVERSITY_WIDGET)) {
    } else if (
      isLogin === "1" ||
      (keepSignedIn &&
        !isNull(tokenExpiry) &&
        moment().add(5, "minutes").unix() <= moment(tokenExpiry).unix())
    ) {
      console.log("Token is not expired!!");
      if (
        isWidgetUser &&
        token &&
        !location.pathname.includes(ROUTES.NOTIFICATIONS_UNSUBSCRIBE)
      ) {
        console.log("Checking token for widget user");

        checkUniversityToken();
      } else {
        if (
          (location.pathname === ROUTES.BASE ||
            location.pathname.includes(ROUTES.SCHOOL) ||
            UNPROTECTED_ROUTES.includes(location.pathname)) &&
          !location.pathname.includes(ROUTES.NOTIFICATIONS_UNSUBSCRIBE)
        ) {
          console.log(
            "Redirecting to dashboard page if already login an requesting unprotected route"
          );
          navigate(ROUTES.DASHBOARD);
        } else {
          console.log("Allow to access requested page if already login");
        }
      }
    } else if (
      !location.pathname.includes(ROUTES.SCHOOL) &&
      !UNPROTECTED_ROUTES.includes(location.pathname)
    ) {
      // Redirect to login if not logged in or no token found and trying to access protected route
      console.log("Redirecting to login");
      navigate(ROUTES.LOGIN_WITH_PARAMS, { replace: true });
    } else {
      console.log("Preserve path");
    }

    // HTTP Pooling of list_messages API using useChatsList Hook
    const listMessagesIntervalAppJS = setInterval(() => {
      if (!reloadChats) dispatch(setReloadChats(true));
    }, [LIST_MESSAGE_API_POOL_DELAY]);

    return () => {
      clearInterval(listMessagesIntervalAppJS);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedSchool && Object.keys(selectedSchool).length !== 0) {
      // Update theme color
      dispatch(setThemeColor(selectedSchool.theme_color));
      // Reload chat list
      dispatch(setReloadChats(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool]);

  useEffect(() => {
    applyTheme(
      preservedState.themeColor
        ? preservedState.themeColor
        : DEFAULT_PRIMARY_COLOR
    );
  }, [preservedState.themeColor]);

  // Need to tarck if user is visiting portal for the first time
  // Only one event should be tracked per day
  // Need to track for loggedin and new users as well that's why using preserved state
  useEffect(() => {
    if (
      !preservedState.lastPerDayAppLoad ||
      !moment(preservedState.lastPerDayAppLoad).isSame(moment(), "day")
    ) {
      Mixpanel.track("Portal visited");
      // Update redux storage
      dispatch(setLastPerDayAppLoad(moment()));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  /**
   * This useEffect tracks the pathname or url and calls updateLastActiveSessionAPI
   * API, with a time delay, if the user is logged in. Upon sucessful call redux store is updated
   */
  useEffect(() => {
    if (!isLogin) return;
    if (location?.pathname === "/login") return; // Edge case of duplicate API call upon login
    // IIFE for API call
    (async () => {
      const currentLastActiveSession = new Date(lastActiveSession);
      const currentDataTime = new Date();
      if (currentDataTime - currentLastActiveSession > 3600000) {
        const response = await updateLastActiveSessionAPI();
        if (response.success) {
          // Update redux storage
          dispatch(setLastActiveSession(response.data.last_active_session_at));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  /**
   * Important effect : 1
   * This effect will run after login(Initial school set)/school change to fetch school permissions
   */
  useEffect(() => {
    console.log("School changed -> " + JSON.stringify(selectedSchool));
    if (isLogin) fetchUserPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSchool?.id]);

  /**
   * Important effect : 2
   * This effect will run after school permissions are set upon login
   * This effect will then check if redirectPath / page is permissible to access by user
   * This effect will then redirect to dashboard / saved route as per permission
   */
  useEffect(() => {
    if (isLogin && !isEmpty(permissions)) {
      const redirectPath = getRedirectRouteFromURL(isWidgetUser);

      if (isPageAccessible(permissions, redirectPath)) {
        // Check request page permission
        navigate(redirectPath, { replace: true });
      } else {
        navigate(ROUTES.DASHBOARD, { replace: true });
        toastify(
          "You do not have the necessary permissions to access this page."
        );
      }
      setShowPageLoader(false);

      // Need to prevent wiping out route on initial selectedSchool as the school will be changed after with latest school
      // Need to wipe out only when latest school is set
      if (token && selectedSchool?.token !== token) {
        console.log("Not wiping out route as token school is not set!");
        return;
      }

      if (savedRoute && !isEmpty(savedRoute)) {
        // Need to wipe out saved route once user is logged in and route is used once for redirection
        console.log("Wiping out -> " + JSON.stringify(savedRoute));
        dispatch(wipeOutSavedRoute());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  useEffect(() => {
    setTitleForPath(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const routes = useRoutes([
    {
      path: ROUTES.BASE,
      element: <Login />,
    },
    {
      path: ROUTES.LOGIN,
      element: <Login />,
    },
    {
      path: `${ROUTES.SCHOOL}/:token`,
      element: <Login />,
    },
    {
      path: `${ROUTES.UNIVERSITY_WIDGET}/:university_token`,
      element: <UniversityWidget />,
    },
    {
      path: `${ROUTES.UNIVERSITY_WIDGET_CHAT_POPUP}/:university_token`,
      element: <UniversityWidgetChatPopup />,
      // Multiple route parameters
      children: [
        {
          // Optional parameter
          path: `:user_id`,
          element: <UniversityWidgetChatPopup />,
        },
      ],
    },
    {
      path: `${ROUTES.UNIVERSITY_WIDGET_CHAT}/:university_token`,
      element: <UniversityWidgetChat />,
    },
    {
      path: ROUTES.CONFIRM_EMAIL,
      element: <ConfirmEmail />,
    },
    {
      path: ROUTES.AMBASSADOR_SIGN_UP,
      element: <AmbassadorSignUp />,
    },
    {
      path: ROUTES.INVITED_SIGN_UP,
      element: <ProspectParentInviteSignUp />,
    },
    {
      path: ROUTES.AMBASSADOR_ACCEPT_INVITE,
      element: <AmbassadorAcceptInvite />,
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      element: <ForgotPassword />,
    },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPassword />,
    },
    {
      path: ROUTES.NOTIFICATIONS_UNSUBSCRIBE,
      element: <NotificationUnsubscribe />,
    },
    {
      path: ROUTES.DASHBOARD,
      element: (
        <MainLayout>
          <Dashboard />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.MESSAGES,
      element: (
        <MainLayout className="app-chat-container">{<Chat />}</MainLayout>
      ),
    },

    {
      path: ROUTES.CHAT.AMBASSADORS,
      element: (
        <MainLayout className="app-chat-container">
          <DiscoverNetwork />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.COMMUNITY.PATH,
      element: (
        <MainLayout className="app-chat-container">
          <Community />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.COMMUNITY.TOPICS,
      element: (
        <MainLayout className="app-chat-container">
          <Topics />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.PROFILE,
      element: (
        <MainLayout>
          <Profile />
        </MainLayout>
      ),
    },

    {
      path: ROUTES.DISCOVER.WEBINARS,
      element: (
        <MainLayout>
          <WebinarsV2 />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.DISCOVER.RESOURCES,
      element: (
        <MainLayout>
          <Resources />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.DISCOVER.STUDENT_SERVICES,
      element: (
        <MainLayout>
          <StudentServices />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.DISCOVER.EMPLOYMENT_DATA_HUB,
      element: (
        <MainLayout>
          <EmploymentDataHub />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.TOOLS.CHECKLISTS,
      element: (
        <MainLayout>
          <Checklists />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.DISCOVER.COURSES,
      element: (
        <MainLayout>
          <Courses />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.CAMPUS_MAP,
      element: (
        <MainLayout>
          <CampusMap />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.CALCULATOR,
      element: (
        <MainLayout>
          <Calculator />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.NOTIFICATIONS,
      element: (
        <MainLayout>
          <Notifications />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.NOTIFICATIONS_SETTINGS,
      element: (
        <MainLayout>
          <NotificationsSettings />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.HELP_CENTER,
      element: (
        <MainLayout>
          <HelpCenter />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.PRIVACY_POLICY,
      element: (
        <MainLayout>
          <PrivacyPolicy />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.TERMS_AND_CONDITIONS,
      element: (
        <MainLayout>
          <TermsAndConditions />
        </MainLayout>
      ),
    },
    {
      path: ROUTES.LINKEDIN,
      element: <LinkedInCallback />,
    },
    {
      path: "*",
      element: <Login />,
    },
  ]);
  return (
    <>
      {/* We need to save initial app load route for further redirection after login/signup, before any of the child components performs any redirection*/}
      <ParentEffect effect={initialEffect} />
      {showPageLoader && <InterstridePageLoader />}
      {routes}
      <ToastContainer />
    </>
  );
}
