import React, { memo, useState, useEffect } from "react";
import { createModuleStyleExtractor } from "../../../utils/css";
import toastify from "../../../utils/toast";
import { isNull } from "../../../utils/validations";
import styles from "./FileUploadIcon.module.scss";

const cx = createModuleStyleExtractor(styles);

const DEFAULT_MAX_FILE_SIZE = 2097152; // 2MB
const DEFAULT_ALLOWED_FILES = ["image/png", "image/jpg", "image/jpeg"];
const DEFAULT_ICON_IMG =
  "https://interstride.s3.amazonaws.com/miscellaneous/assets/media/ic_attachment.svg";

const FileUploadIcon = ({
  maxFileSize = DEFAULT_MAX_FILE_SIZE,
  allowedFiles = DEFAULT_ALLOWED_FILES,
  setSelectedFile = () => {}, // Base 64 encoded Data URI
  selectedFile,
}) => {
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    // Reset file name
    if (isNull(selectedFile)) setFileName(null);
  }, [selectedFile]);

  const handleFileChange = (event) => {
    // Reset file name
    setFileName(null);

    const file = event.target?.files[0];

    // Check if file is present
    if (!file) {
      setSelectedFile(null);
      return;
    }

    // Check if file is under allowed max upload file size
    if (file.size > maxFileSize) {
      toastify(`Please select a file less than 2 MB`);
      setSelectedFile(null);
      return;
    }

    // Check image type
    if (!allowedFiles.includes(file.type)) {
      toastify("Please select a valid image (png, jpg, jpeg)");
      setSelectedFile(null);
      return;
    }

    // Set File Name
    setFileName(file.name);
    setSelectedFile(file);
  };

  return (
    <div className={cx("a-file-upload-wrapper")}>
      <label htmlFor="file-input-icon" className={cx("a-file-upload-label")}>
        <img src={DEFAULT_ICON_IMG} alt="upload-file" />
      </label>
      <input id="file-input-icon" type="file" onChange={handleFileChange} />
      {fileName !== null && <span>{fileName}</span>}
    </div>
  );
};

export default memo(FileUploadIcon);
