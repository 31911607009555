import React, { memo, useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./PasswordTextbox.module.scss";
import MuiTextbox from "../MuiTextbox/MuiTextbox";

const cx = createModuleStyleExtractor(styles);

// HOC for password type textbox to handle show/hide of password using eye icon
const PasswordTextbox = ({ error, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={cx("a-password-textbox")}>
      <MuiTextbox
        {...props}
        type={showPassword ? "text" : "password"}
        error={error}
        sx={{
          border: `2px solid ${error ? "#f7645f" : "#d1dde5"}`,
          borderRadius: "45px",
          backgroundColor: "#FFF",

          "& .MuiInputBase-input": {
            border: "none",
            textAlign: "center",
          },

          "& .MuiInputAdornment-root": {
            margin: "5px 0px 5px 0px",
          },

          "& .MuiIconButton-root": {
            margin: "0px !important",
          },
        }}
        InputProps={{
          startAdornment: (
            <div className={cx("a-password-textbox__start-adornment")}></div>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label={
                  showPassword ? "hide the password" : "display the password"
                }
                onClick={() => setShowPassword((currentState) => !currentState)}
                onMouseDown={(e) => e?.preventDefault && e.preventDefault()}
                onMouseUp={(e) => e?.preventDefault && e.preventDefault()}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOffOutlined htmlColor="#607790" />
                ) : (
                  <VisibilityOutlined htmlColor="#607790" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
export default memo(PasswordTextbox);
