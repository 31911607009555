import React from "react";

import { Masonry } from "@mui/lab";

import { Button } from "../../atoms/Button";
import WidgetFadedBanner from "../../atoms/WidgetFadedBanner/WidgetFadedBanner";

import styles from "./WidgetCourses.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";
import { MASTERCLASS_MODULES } from "../../../utils/constants";
import { noop } from "../../../utils/noop";

import F1CourseIcon from "../../../assets/images/f1course_preview.png";

const cx = createModuleStyleExtractor(styles);

const WidgetCourses = ({ showOverlay = noop }) => {
  const handleSignupClick = () => {
    showOverlay("Get full access to all our courses and learning materials");
  };

  return (
    <div className={cx("a-widget-courses-wrapper")}>
      <div className={cx("a-widget-courses-wrapper__masterclass")}>
        <Masonry columns={2}>
          {MASTERCLASS_MODULES?.map((course) => (
            <div className={cx("a-widget-courses-wrapper__masterclass__card")}>
              <img src={course?.icon} alt="masterclass" />
              <div
                className={cx(
                  "a-widget-courses-wrapper__masterclass__card__details"
                )}
              >
                <p>{course?.name ?? ""}</p>
                <h3>{course?.title ?? ""}</h3>
                <p>{course?.desc ?? ""}</p>
                <Button onClick={handleSignupClick}>Take course</Button>
              </div>
            </div>
          ))}
        </Masonry>
        <div className={cx("a-widget-courses-wrapper__masterclass__banner")}>
          <WidgetFadedBanner
            actionText="Unlock to access full course"
            onClick={handleSignupClick}
          />
        </div>
      </div>
      <div className={cx("a-widget-courses-wrapper__f1")}>
        <h2>A guide to the F-1 student visa</h2>
        <img
          src={F1CourseIcon}
          alt="A guide to the F-1 student visa"
          onClick={handleSignupClick}
        />
      </div>
    </div>
  );
};

export default WidgetCourses;
